import { combineReducers } from "@reduxjs/toolkit";
import globalLoaderSlice from "../../redux/slice/global.loader.slice";
import manageRebateSlice from "../../redux/slice/manage.rebate.slice";
import medicineSlice from "../../redux/slice/medicine.slice";
import drugsSlice from "../../redux/slice/drug.request.form.slice";
import medicineOrdersSlice from "../../redux/slice/medicine.orders.slice";

const rootReducer = combineReducers({
  medicine: medicineSlice,
  manageRebate: manageRebateSlice,
  globalLoader: globalLoaderSlice,
  drugs: drugsSlice,
  medicineOrders: medicineOrdersSlice,
});

export default rootReducer;
