import * as Yup from "yup";

const login = () =>
  Yup.object().shape({
    email: Yup.string().trim().email().required("Email is required"),
    password: Yup.string().required("Please enter password"),
  });
const signup = () =>
  Yup.object().shape({
    email: Yup.string().trim().email().required("Email is required"),
    password: Yup.string().required("Please enter password"),
    firstName: Yup.string().required("Please enter first name"),
    lastName: Yup.string().required("Please enter last name"),
    title: Yup.string().required("Please select title"),
    phonecode: Yup.string().required("Please select phone code"),
    phone_number: Yup.string().required("Please enter phone number"),
    city: Yup.string().required("Please select city"),
    country: Yup.string().required("Please select country name"),
    manufactureCompany: Yup.string().required("Please select manufacture company")
  });

const forgotPassword = () =>
  Yup.object().shape({
    email: Yup.string().trim().email().required("Email is required"),
  });

const resetPassword = () =>
  Yup.object().shape({
    newPassword: Yup.string()
      .min(8, "Must be greater then 8 characters")
      .required("Please enter new password")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirmNewPassword: Yup.string()
      .required("Please enter confirm new password")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });

const updatePassword = () =>
  Yup.object().shape({
    oldPassword: Yup.string().required("Please enter old password"),
    newPassword: Yup.string()
      .min(8, "Must be greater then 8 characters")
      .required("Please enter new password")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirmNewPassword: Yup.string()
      .required("Please enter confirm new password")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });

const manageRebateFilter = () =>
  Yup.object().shape({
    fromDate: Yup.date().optional(),
    toDate: Yup.date().optional(),
  });

const medicineFilter = () =>
  Yup.object().shape({
    fromDate: Yup.date().optional(),
    toDate: Yup.date().optional(),
    insuranceCompanyId: Yup.string().trim().optional(),
  });

  const medicineOrderFilter = () =>
  Yup.object().shape({
    fromDate: Yup.date().optional(),
    toDate: Yup.date().optional(),
    status: Yup.string().trim().optional(),
    pharmacyId: Yup.string().trim().optional(),
  });

const requestContent = () =>
  Yup.object().shape({
    // apiName: Yup.string()
    //   .required("Please search api name"),
    // tradePrice: Yup.string()
    //   .required("Please enter trade price"),
    // rebatePercentage: Yup.string()
    //   .required("Please enter rabate percentage"),

  });

const applicationFormulary = () =>
  Yup.object().shape({
    NameOfCompany: Yup.string()
      .min(8, "Must be greater then 8 characters")
      .max(80, "Must be less then 50 characters")
      .trim().required('Name of Company is required'),
    BusinessRegistrationNumber: Yup.string().trim().required('KRA PIN Number is required'),
    OfficialEmail: Yup.string().trim().email().required('Office Email is required'),
    // country: Yup.number().required('Country is required'),
    // YearEstablished: Yup.string()
    //   .min(4, "Must be 4 characters")
    //   .max(4, "Must be 4 characters")
    //   .optional(),
    TelephoneNumber: Yup.string().trim().required('Telephone Number is required'),
    AlternativeContact: Yup.string().trim().required('Alternative Contact is required'),
    CompanyWebsite: Yup.string()
      .optional()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Enter correct url!'
      )



  });

const SCHEMA = {
  login,
  signup,
  forgotPassword,
  resetPassword,
  updatePassword,
  manageRebateFilter,
  medicineFilter,
  requestContent,
  applicationFormulary,
  medicineOrderFilter
};

export default SCHEMA;
