/**
 * Envionemnt Variables
 */
export const Env = {
    MODE:       process.env.NODE_ENV || 'development',
    APP_NAME:   process.env.REACT_APP_NAME || 'LIVIA Portal',
    AUTH_BASE_URL:   process.env.REACT_APP_AUTH_BASE_URL,
    ADMIN_BASE_URL:   process.env.REACT_APP_ADMIN_BASE_URL,
    BASE_URL:   process.env.REACT_APP_BASE_URL,
    BACKEND_URL:   process.env.REACT_APP_BACKEND_URL,
    REACT_APP_VERSON:   process.env.REACT_APP_VERSON || '/v1',
    REACT_APP_ASSET_PATH:   process.env.REACT_APP_ASSET_PATH,
    REACT_APP_BACKEND_API: process.env.REACT_APP_BACKEND_API,
    REACT_APP_S3_BUCKET: process.env.REACT_APP_S3_BUCKET,
    REACT_APP_S3_ACCESS_KEY: process.env.REACT_APP_S3_ACCESS_KEY,
    REACT_APP_S3_SECRET_KEY: process.env.REACT_APP_S3_SECRET_KEY,
    REACT_APP_S3_REGION: process.env.REACT_APP_S3_REGION,
    REACT_APP_X_KEY: '5066e109-84d6-4df5-8a50-dedf1293971d'
};