import React, { useEffect, useState } from "react";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import { debounce } from "lodash";
import { Form, Formik } from "formik";
import FORM_FIELD from "../../validations/form-field";
import SCHEMA from "../../validations/schema";
import { Link, useNavigate } from "react-router-dom";

import {
  globalLoaderFalse,
  globalLoaderTrue,
} from "../../redux/slice/global.loader.slice";
import { useDispatch, useSelector } from "react-redux";
import { requestContentAction } from "./action";
import { IFilter } from "../formulary-applications/models";
import { RootState } from "../../setup/store/store";
import showAlert from "../../common/utils/alert";
import { EndPoint, ResponseCode, RoutesName } from "../../common/constants";

import useDebounce from "../../hooks";
import axiosI from "../../setup/axios/axios.instance";

const initialValues = {
  apiName: "",
  brandName: "",
  tradePrice: "",
  rebatePercentage: 0,
  nonBranded: "",
};

function RequestFormulary() {
  const { Brands, TotalRecords } = useSelector(
    (state: RootState) => state.medicine?.entities
  );

  useEffect(() => {
    //dispatch(getMedicineList(filterValue) as any);
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [nonBranded, setNonBranded]: any = useState("");
  const [apiOption, setApiOptions] = useState([]);
  const [value, setValue] = useState<any>("");
  const [isNotValid, setNotValid] = useState<any>("");
  const [options, setOptions] = useState<any[]>([]);
  const [initialValue, setInitialValue] = useState<any>(initialValues);
  const [inputValue, setInputValue] = useState("");

  const [brandName, setBrandName] = useState("");
  const [tradePrice, setTradePrice] = useState("");
  const [rebatePercentage, setRebatePercentage] = useState("");

  const debouncedSearchTerm: string = useDebounce<string>(
    inputValue && inputValue,
    500
  );

  useEffect(() => {
    if (Brands.length > 0) {
      let apiArr: any = [];
      Brands.map((res: any) => {
        let newApi = {
          label: res?.API,
          id: res?.BrandId,
        };
        apiArr.push(newApi);
      });
      setApiOptions(apiArr);
    }
  }, Brands);

  const onSubmit = (values: any, { resetForm }: any) => {
    if (!initialValue.apiName) {
      showAlert(2, "Please select API name");
      // action.setSubmitting(false);
      setNotValid(true);
      return;
    }
    if (!brandName && !nonBranded) {
      showAlert(2, "Please enter brand name");
      // action.setSubmitting(false);
      setNotValid(true);
      return;
    }
    if (!tradePrice) {
      showAlert(2, "Please enter trade price");
      // action.setSubmitting(false);
      setNotValid(true);
      return;
    }
    if (!rebatePercentage) {
      showAlert(2, "Please enter rebate percentage");
      // action.setSubmitting(false);
      setNotValid(true);
      return;
    }

    const payload = {
      RequestContent: initialValue.apiName,
      BrandName: brandName,
      TradePrice: tradePrice,
      RebatePercentage: parseFloat(rebatePercentage),
    };
    dispatch(globalLoaderTrue());
    requestContentAction(payload)
      .then((res: any) => {
        if (res?.data?.IsSuccess == true) {
          showAlert(
            1,
            "Thank you! your request has been received. One of our representative will contact you soon!"
          );
          resetForm();
          setBrandName("");
          setTradePrice("");
          setRebatePercentage("");
          setValue("");
          dispatch(globalLoaderFalse());
        } else {
          resetForm();
          setBrandName("");
          setTradePrice("");
          setRebatePercentage("");
          setValue("");
          dispatch(globalLoaderFalse());
        }
      })
      .catch((err) => {
        dispatch(globalLoaderFalse());
        // action.setSubmitting(false);
      });
  };

  const handleNonBranded = () => {
    setNonBranded(!nonBranded);
    setInitialValue({
      ...initialValue,
      brandName: nonBranded ? "" : "Not Branded",
    });
    setBrandName(nonBranded ? "" : "Not Branded");
  };

  const handleBrandName = (event: any) => {
    setBrandName(event.target.value);
  };

  const handleRebatePercentage = (event: any) => {
    const { value, name } = event.target;
    if (/^\d+$/.test(value) && value < 100) {
      setRebatePercentage(value);
    } else if (value == "") {
      setRebatePercentage(value);
    }
  };

  const filterOptions = createFilterOptions({
    stringify: (option: any) =>
      `${option.name} ${option?.api} ${option?.searchDrug}`,
  });

  const handelTradePrice = (evt: any) => {
    const { value, name } = evt.target;
    if (/^\d+$/.test(value)) {
      setTradePrice(value);
    } else if (value == "") {
      setTradePrice(value);
    }
  };
  useEffect(() => {
    let actives = true;

    if (debouncedSearchTerm === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (debouncedSearchTerm.length > 0) {
      let Page = 1;
      let Limit = 100;
      let InsuranceCompanyId = "";
      let FromDate = "";
      let ToDate = "";
      let Search = debouncedSearchTerm;
      console.log("Search", Search);
      const queryString = `?IsManufactureSearch=1&Page=${Page}&Limit=${Limit}&InsuranceCompanyId=${InsuranceCompanyId}&FromDate=${FromDate}&ToDate=${ToDate}&Search=${Search}`;
      dispatch(globalLoaderTrue());
      const response: any = axiosI
        .get(`${EndPoint.MEDICINE_LIST}${queryString}`)
        .then((res: any) => {
          if (res?.data?.IsSuccess) {
            const results: any = [...res?.data?.Data?.Brands];
            if (actives) {
              let newOptions: any = [];
              let newResults: any = [];
              for (let ele of results) {
                newResults.push({
                  ...ele,
                  searchDrug: debouncedSearchTerm,
                });
              }
              if (results) {
                newOptions = [...newOptions, ...newResults];
              }
              setOptions(newOptions);
            }
          }
          dispatch(globalLoaderFalse());
        })
        .catch((errr) => {
          dispatch(globalLoaderFalse());
        });
    }

    return () => {
      actives = false;
    };
  }, [value, debouncedSearchTerm]);

  return (
    <>
      <div className="page-manage-rebate bg-white">
        <div style={{ textAlign: "justify" }}>
          <p style={{ padding: "10px" }} className="txt-content">
            As a Pharmacy Benefits Management Company (PBM), Livia Health is
            committed to delivering quality and affordable care to its medically
            insured members. This guarantees not only accessibility to current
            drug regimen for disease states but also ensures a slower benefit
            depletion rate for members.
          </p>{" "}
          <p style={{ padding: "10px" }} className="txt-content">
            As a pharma manufacturer / importer, we value the role you play in
            helping us achieve our above goals. To this effect, we welcome you
            to apply for inclusion into our formulary, your quality products.
          </p>
          <p style={{ padding: "10px" }} className="txt-content">
            {" "}
            Our Clinical and Therapeutics Committee is tasked with developing
            and maintaining a formulary that gives the best outcome for disease
            states currently under our pharmacy benefits management.
          </p>
          <p style={{ padding: "10px" }} className="txt-content">
            {" "}
            A non-refundable application fee of USD5,000.00 is applicable for
            every unique drug. A non-refundable fee of USD3,000.00 is applicable
            for each extra strength for that drug that you wish included in the
            formulary.
          </p>
          <p style={{ padding: "10px" }} className="txt-content">
            {" "}
            The formulary will comprise of only two brands (branded generic and
            / or the innovator) per active molecule hence giving exposure to the
            selected brands to our member base of over 500.000 individuals.
          </p>
          <p style={{ padding: "10px" }} className="txt-content">
            {" "}
            As a PBM, we ensure that only the brands that exist in the formulary
            are dispensed and as thus medical claims with brands outside of the
            formulary are declined. This ensures that pharmacies and hospitals
            only dispense brands that are included in the formulary.{" "}
          </p>{" "}
        </div>

        <br />
        <div
          className="page-drug-formulary bg-white"
          style={{ border: "2px solid lightgray" }}
        >
          <Grid container sx={{ mb: "20px", padding: "20px" }} spacing={3}>
            <div style={{ margin: "auto", width: "90%", padding: "10px" }}>
              <h2 style={{ textAlign: "center" }}>Request Application Form</h2>
              <br />
              <Formik
                initialValues={initialValue}
                validationSchema={SCHEMA.requestContent}
                onSubmit={onSubmit}
                resetForm
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => {
                  return (
                    <Form onSubmit={handleSubmit} className="w-50">
                      <Grid container spacing={4}>
                        <Grid item xs={12} lg={6} xl={4}>
                          {/* <TextField> */}
                            <Autocomplete
                              value={value ? value : null}
                              size="small"
                              fullWidth
                              placeholder="Select API name"
                              filterOptions={filterOptions}
                              onChange={(event: any, newValue: any) => {
                                setOptions(
                                  newValue ? [newValue, ...options] : options
                                );
                                setValue(newValue);

                                setInitialValue({
                                  ...initialValue,
                                  apiName: newValue.API,
                                });
                                if (newValue === null) {
                                  setInitialValue(
                                    FORM_FIELD.requestContentForm.apiName
                                  );
                                }
                              }}
                              inputValue={inputValue}
                              onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                              }}
                              getOptionLabel={(option: any) => {
                                return `${option.API}`;
                              }}
                              id="controllable-states-demo"
                              options={options}
                              renderOption={(props, option: any) => {
                                return (
                                  <>
                                    <li {...props} key={Math.random()}>
                                      <p>{option.API} </p>
                                    </li>
                                  </>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                
                                  {...params}
                                  name="apiName"
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                  placeholder="Select API name"
                                  error={Boolean(
                                    errors.apiName &&
                                      touched.apiName &&
                                      errors.apiName
                                  )}
                                  helperText={Boolean(
                                    errors.apiName &&
                                      touched.apiName &&
                                      errors.apiName
                                  )}
                                />
                              )}
                            />
                          {/* </TextField> */}
                        </Grid>
                        <Grid item xs={12} lg={6} xl={4}>
                          <TextField
                            multiline
                            type="text"
                            id="outlined-basic"
                            label="Enter Brand name"
                            variant="outlined"
                            disabled={nonBranded}
                            name="brandName"
                            onBlur={handleBlur}
                            onChange={handleBrandName}
                            value={brandName}
                          />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="nonbranded"
                                value={initialValue.nonBranded}
                                onChange={handleNonBranded}
                              />
                            }
                            label="Non Branded"
                          />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={4}>
                          <TextField
                            multiline
                            type="number"
                            id="outlined-basic"
                            label="Trade Price"
                            variant="outlined"
                            name="tradePrice"
                            onChange={handelTradePrice}
                            onBlur={handleBlur}
                            value={tradePrice}
                          />
                        </Grid>

                        <Grid item xs={12} lg={6} xl={4}>
                          <TextField
                            multiline
                            type="text"
                            id="outlined-basic"
                            label="Rebate Percentage"
                            variant="outlined"
                            name="rebatePercentage"
                            onChange={handleRebatePercentage}
                            onBlur={handleBlur}
                            value={rebatePercentage}
                          />
                        </Grid>
                      </Grid>
                      <div
                        className="btn-cover mt-30"
                        style={{ marginLeft: "70px", marginRight: "5px" }}
                      >
                        <button
                          type="submit"
                          style={{ margin: "auto" }}
                          className="btn btn-primary w-50"
                        >
                          {isSubmitting && !isNotValid
                            ? "Submitting..."
                            : "Request Formulary Inclusion"}
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Grid>
        </div>
        <br />
        <br />
        <p>
          Once your request for consideration has been received, one of our
          executives will reach out to you to finalize on the process. At that
          time an invoice will be shared with you by the executive. Once you
          have made the payments, you will receive an{" "}
          <strong>invitation link</strong> where you will lodge in your
          application, you will receive the invitation link. If your application
          is successful, you shall be invited to discuss modalities of this
          engagement, execute a contract and pay an annual retention fee that is
          calculated based on the weighted annual disease burden for the member
          population.
        </p>
      </div>
    </>
  );
}

export default RequestFormulary;
