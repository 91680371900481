export const EndPoint = {
  LOGIN: "/login",
  SIGNUP: "/manufacturer-signup",
  FORGOT_PASSWORD: "/password/forgot",
  RESET_PASSWORD: "/password/reset",
  VERIFY_RESET_PASSWORD_LINK: "/password/verify-secret-code",
  REFRESH_TOKEN: "/refresh/token",
  LOGOUT: "/logout",
  UPDATE_PASSWORD: "/password/change",
  INSURANCE_COMPANY_LIST: "/insurance-company",
  MANAGE_REBATE_LIST: "/rebate-amount/list",
  MANAGE_REBATE_LIST_EXPORT: "/rebate-amount/list-export",
  MEDICINE_LIST: "/brand/list",
  PHARMACY_LIST: "/brand/get-pharmacies",
  MEDICINE_API_LIST: "/brand/list-api",
  MEDICINE_LIST_EXPORT: "/brand/list-export",
  MEDICINE_ORDER_EXPORT: "/medicine-orders/export-medicine-orders",
  REBATE_DETAILS_EXPORT: "/brand/order-list-export",
  FORMULARY_INCLUSION: "/formulary-inclusion",
  DRUG_REQUEST_FORM: "/formulary-inclusion/update-drug-request-form",
  GET_DRUG_REQUEST_FORM: "/formulary-inclusion/drug-request-form-detail",
  MANUFACTURER:"/signup/get-manufacturers/",
  CITIES:"/signup/get-cities/",
  REQUEST_TP_UPDATE:"/request-tp-update",
  MEDICINE_ORDER_LIST: "/medicine-orders/medicine-orders",
  MEDICINE_ORDER_DETAILS: "/medicine-orders/medicine-order-details",
  MEDICINE_ORDER_SUBMIT_QUOTE: "/medicine-orders/update-medicine-order-price",
};

