import axios from "axios";
import { EndPoint, Env } from "../../common/constants";
import axiosI from "../../setup/axios/axios.instance";

export const fetchCsvData = async (filterValue: any) => {
  try {
    const filterParams = `?InsuranceCompanyId=${filterValue.InsuranceCompanyId}&FromDate=${filterValue.FromDate}&ToDate=${filterValue.ToDate}&Search=${filterValue.Search}`;
    const response = await axiosI.get(
      `${EndPoint.MEDICINE_LIST_EXPORT}${filterParams}`
    );
    return response?.data;
  } catch (err) {
    return err;
  }
};

export const fetchRebateDetailsCsvData = async (
  filterValue: any,
  brandId: number = 0
) => {
  try {
    const filterParams = `?InsuranceCompanyId=${filterValue.InsuranceCompanyId
      }&FromDate=${filterValue.FromDate}&ToDate=${filterValue.ToDate}&BrandId=${brandId !== 0 ? brandId : ""
      }`;
    const response = await axiosI.get(
      `${EndPoint.REBATE_DETAILS_EXPORT}${filterParams}`
    );
    return response?.data;
  } catch (err) {
    return err;
  }
};

export const getInsuranceCompanyList = async () => {
  try {
    const response = await axios.get(
      `${Env.ADMIN_BASE_URL}${Env.REACT_APP_VERSON}${EndPoint.INSURANCE_COMPANY_LIST}`
    );
    return response;
  } catch (err) {
    return err;
  }
};

export async function addDrugRequestForm(payload: any) {
  try {
    const response = await axiosI.patch(
      `${Env.BASE_URL}${Env.REACT_APP_VERSON}${EndPoint.DRUG_REQUEST_FORM}` as string,
      payload
    );
    return response;

  } catch (err: any) {
    return err;
  }
}

export async function getDrugRequestFormById(Id: any) {
  try {
    const response = await axiosI.get(
      `${Env.BASE_URL}${Env.REACT_APP_VERSON}${EndPoint.GET_DRUG_REQUEST_FORM}/${Id}`
    );
    return response?.data;

  } catch (err: any) {
    return err;
  }
}
