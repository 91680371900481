import React from "react";
import { AppBar, Box, Theme } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import logoImg from "../../assets/images/logo.svg";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Logout from "@mui/icons-material/Logout";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import { createStyles, makeStyles } from "@mui/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./header.css";
import { getPageTitle, getUserEmail, getUserName } from "../../common/utils";
import { RoutesName } from "../../common/constants";
import showAlert from "../../common/utils/alert";
import Button from "@mui/material/Button";
import { logoutAction } from "./action";
import {
  globalLoaderFalse,
  globalLoaderTrue,
} from "../../redux/slice/global.loader.slice";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      "&.MuiAppBar-root": {
        boxShadow: "none",
        backgroundColor: "rgb(83, 195, 146)",
        color: "rgba(0, 0, 0, 0.87)",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
      },
    },
    link: {
      font: `Roboto, sans-serif`,
      color: "var(--white)",
      margin: "0 10px",
    },
    active: {
      backgroundColor: "#fff",
      borderTopLeftRadius: "11px 9px",
      borderTopRightRadius: "11px 9px",
      height: "56px",
      color: "var(--light-green) !important",
    },
  })
);

const Header = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    MANAGE_REBATE_LIST,
    MEDICINE_LIST,
    CHANGE_PASSWORD,
    REQUEST_FORMULARY,
    REQUEST_FORMULARY_APPLICATIONS,
    MEDICINE_ORDERS,
    MEDICINE_ORDERS_DETAILS,
  } = RoutesName;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const openMenu = Boolean(anchorMenu);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorMenu(null);
  };

  const handleLogout = () => {
    const payload = {
      PrincipalIdentifierValue: getUserEmail(),
      PlatformEnumId: 3,
    };
    dispatch(globalLoaderTrue());
    logoutAction(payload)
      .then((res: any) => {
        showAlert(1, "You've been logged out successfully.");
        dispatch(globalLoaderFalse());
        navigate(RoutesName.LOGIN);
      })
      .catch((err) => {
        dispatch(globalLoaderFalse());
        navigate(RoutesName.LOGIN);
      });
  };

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <div className="justify-start">
          <div className="menu-cover">
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <MenuIcon sx={{ color: "white" }} />
            </Button>

            <Menu
              id="basic-menu"
              anchorEl={anchorMenu}
              open={openMenu}
              onClose={handleMenuClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleMenuClose}>
                <Link to={MANAGE_REBATE_LIST}>Manage Rebates</Link>
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <Link to={MEDICINE_LIST}>Medicines</Link>
              </MenuItem>
            </Menu>
          </div>

          <Link to="/" className="logo-cover">
            <img src={logoImg} alt="logo" className="logo" />
          </Link>
        </div>
        <div className="menu-options-list">
          <div
            className={`single-menu ${
              location.pathname === MANAGE_REBATE_LIST ? "active" : ""
            }`}
          >
            <Link to={MANAGE_REBATE_LIST}>Manage Rebates</Link>
          </div>
          <div
            className={`single-menu ${
              location.pathname === MEDICINE_LIST ? "active" : ""
            }`}
          >
            <Link to={MEDICINE_LIST}>Medicines</Link>
          </div>
          <div
            className={`single-menu ${
              location.pathname === REQUEST_FORMULARY ? "active" : ""
            }`}
          >
            <Link to={REQUEST_FORMULARY}>Request Formulary Inclusion</Link>
          </div>
          <div
            className={`single-menu ${
              (MEDICINE_ORDERS === location.pathname ||
              location.pathname.includes(MEDICINE_ORDERS_DETAILS))
                ? "active"
                : ""
            }`}
          >
            <Link to={MEDICINE_ORDERS}>Medicine Orders</Link>
          </div>
          <div
            className={`single-menu ${
              location.pathname === REQUEST_FORMULARY_APPLICATIONS
                ? "active"
                : ""
            }`}
          >
            <Link to={REQUEST_FORMULARY_APPLICATIONS}>Applications</Link>
          </div>
        </div>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{
              color: "#fff",
              padding: "5px 5px 5px 10px",
              borderRadius: "5px",
            }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Link to="" className="username">
              Hi, {getUserName()}
            </Link>
            <ExpandMoreIcon />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem>
              <Link to={CHANGE_PASSWORD} className="flex-start">
                <ListItemIcon>
                  <SecurityOutlinedIcon fontSize="small" />
                </ListItemIcon>
                Change Password
              </Link>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </AppBar>
      <div className="breadcrumb-cover">{getPageTitle(location.pathname)}</div>
    </>
  );
};

export default Header;
