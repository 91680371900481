import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  Typography,
  Button,
  Grid,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, createStyles } from "@mui/styles";
import { currencyFormat, getUserID } from "../../common/utils";
import { RootState } from "../../setup/store/store";
import InsuranceMedicineDataDialog from "./insurance-medicine-data-dialog";
import { Brand, InsuranceCompany } from "./models";
import { fetchRebateDetailsCsvData, createTPUpdateRequest } from "./action";
import {
  globalLoaderFalse,
  globalLoaderTrue,
} from "../../redux/slice/global.loader.slice";
import { Box } from "@mui/system";
import Modal from '@mui/material/Modal';
import { styled } from "@mui/system";
import showAlert from "../../common/utils/alert";

const useStyles = makeStyles(() =>
  createStyles({
    csv: {
      color: "var(----light-green)",
    },
  })
);

const StyledModal: any = styled(Modal)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const style = {
  width: 440,
  bgcolor: "#fff",
  boxShadow: "0px 1px 3px 2px rgba(0,0,0,0.2)",
  borderRadius: "10px",
  p: 5,
};

const styleTP = {
  width: 740,
  bgcolor: "#fff",
  boxShadow: "0px 1px 3px 2px rgba(0,0,0,0.2)",
  borderRadius: "10px",
  p: 5,
};

function TableComponent({
  filterValue,
  handleChangePage,
  handleChangeRowsPerPage,
}: any) {
  const { Brands, TotalRecords } = useSelector(
    (state: RootState) => state.medicine?.entities
  );
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [brandId, setBrandId] = useState<number>(0);
  const [openExportRebate, setOpenExportRebate] = useState(false);
  const [openUpdateTP, setOpenUpdateTP] = useState(false);
  const [medicineIndex, setMedicineIndex] = useState<any>([]);
  const [insuranceMedicineData, setInsuranceMedicineData] = useState<
    InsuranceCompany[]
  >([]);
  const [insuranceIdsData, setInsuranceIdsData] = useState<string>("");
  const [insuranceNamesData, setInsuranceNamesData] = useState<string>("");
  const [newTP, setNewTP]: any = useState("");
  const [tpError, settpError] = useState<string>("");

  const handleExportRebateDetailsDownload = () => {
    dispatch(globalLoaderTrue());
    const fileName = `Rebate-details-${new Date().toLocaleString("default", {
      month: "long",
    })}-${new Date().getDate()}.csv`;

    fetchRebateDetailsCsvData(filterValue, brandId)
      .then((res: any) => {
        dispatch(globalLoaderFalse());
        if (res !== undefined) {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;

          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err: any) => {
        dispatch(globalLoaderFalse());
      });
    setOpenExportRebate(false);
  };

  const handleExportRebateClose = () => {
    setBrandId(0);
    setOpenExportRebate(false);
    dispatch(globalLoaderFalse());
  };

  const handleExportRebateOpen = (brandId: number) => {
    setBrandId(brandId);
    setOpenExportRebate(true);
  };

  const handleUpdateTPOpen = (dataObj: any) => {
    setBrandId(brandId);
    setOpenUpdateTP(true);
    setNewTP("");
    let medicineIndex: any = {
      BrandName: dataObj.BrandName,
      API: dataObj.API,
      TradePrice: dataObj.TradePrice,
      BrandId: dataObj.BrandId,
    };
    setMedicineIndex(medicineIndex);
  };

  const handleUpdateTPClose = () => {
    setMedicineIndex([]);
    setNewTP("");
    setOpenUpdateTP(false);
    settpError("");
  };

  const setTradePrice = (value: any) => {
    settpError("");
    setNewTP(value);
  };

  const handleRequestNewTP = () => {
    if (!newTP) {
      settpError("Please enter new trade price");
      return;
    }
    if (newTP == 0) {
      settpError("Please enter valid new trade price");
      return;
    }
    const payload = {
      BrandId: medicineIndex.BrandId,
      RequestedTradePrice: newTP,
      RequestedBy: getUserID(),
      TradePrice:medicineIndex.TradePrice
    };

    dispatch(globalLoaderTrue());

    createTPUpdateRequest(payload)
      .then((res: any) => {
        dispatch(globalLoaderFalse());
        console.log("res", res);
        if (res !== undefined && res.data.code === 200) {
          showAlert(
            1,
            "Request for Trade Price Update has been sent successfully."
          );
          setOpenUpdateTP(false);
        } else {
          showAlert(
            2,
            "You have already requested to update TP for this Medicine which is in pending approval status."
          );
        }
      })
      .catch((err: any) => {
        dispatch(globalLoaderFalse());
      });
  };

  return (
    <>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={openUpdateTP}
        onClose={handleUpdateTPClose}
        className="modal-overlay"
      >
        <Box sx={styleTP}>
          <Typography
            id="modal-modal-description"
            variant="h5"
            className="center regular-bold"
            sx={{ mb: 5 }}
          >
            <b>Request to Update Trade Price</b>
          </Typography>
          <Grid container spacing={5}>
            <Grid item md={6}>
              <Box sx={{ mb: 5 }}>
                <Typography style={{width:"max-content"}} >
                  <b>Brand Name</b> : {medicineIndex.BrandName}
                </Typography>
                <Typography style={{width:"max-content"}} >
                  <b>API Name</b> : {medicineIndex.API}
                </Typography>
                <Typography>
                  <b>Trade Price</b> : {medicineIndex.TradePrice}
                </Typography>
              </Box>
              <Box sx={{ mb: 5 }}>
                {/* <label>Request New Trade Price</label> */}
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Enter New Trade Price"
                  variant="outlined"
                  label="New Trade Price"
                  type="number"
                  value={newTP}
                  onChange={(e) => setTradePrice(e.target.value)}
                />
                {tpError && <label style={{ color: "red" }}>{tpError}</label>}
              </Box>
            </Grid>
            <Grid item md={6}></Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item md={6}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleUpdateTPClose}
                className="w-100"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item md={6}>
              <Button
                variant="contained"
                color="primary"
                sx={{ color: "var(--white)" }}
                onClick={handleRequestNewTP}
                className="w-100"
              >
                Request LIVIA to Update TP
              </Button>
            </Grid>
          </Grid>
        </Box>
      </StyledModal>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={openExportRebate}
        onClose={handleExportRebateClose}
        className="modal-overlay"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-description"
            variant="h5"
            className="center regular-bold"
            sx={{ mb: 5 }}
          >
            Are you sure you want to Export?
          </Typography>
          <Grid container spacing={5}>
            <Grid item md={6}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleExportRebateClose}
                className="w-100"
              >
                No
              </Button>
            </Grid>
            <Grid item md={6}>
              <Button
                variant="contained"
                color="primary"
                sx={{ color: "var(--white)" }}
                onClick={handleExportRebateDetailsDownload}
                className="w-100"
              >
                Yes
              </Button>
            </Grid>
          </Grid>
        </Box>
      </StyledModal>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="medium">
          <TableHead>
            <TableRow>
              <TableCell>Name of the Brand</TableCell>
              <TableCell>Insurance Company</TableCell>
              <TableCell>API of the Brand</TableCell>
              <TableCell>No. of Prescription</TableCell>
              <TableCell>Quantity Sold</TableCell>
              <TableCell>Total Rebate Amount</TableCell>
              <TableCell style={{ paddingLeft: "100px" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Brands.length > 0 ? (
              Brands.map(
                (
                  {
                    BrandName,
                    InsuranceCompanyNames,
                    API,
                    TotalPrescribed,
                    InsuranceCompanyAssociationWithBrand,
                    InsuranceCompanyIds,
                    BrandId,
                    TradePrice,
                  }: Brand,
                  index
                ) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{BrandName ? BrandName : "N/A"}</TableCell>
                      <TableCell>
                        {InsuranceCompanyNames ? (
                          InsuranceCompanyNames.split(",").length > 1 ? (
                            <div>
                              {InsuranceCompanyNames.split(",")[0] +
                                ", " +
                                InsuranceCompanyNames.split(",")[1]}
                              <div
                                onClick={() => {
                                  setOpen(true);
                                  setInsuranceMedicineData(
                                    InsuranceCompanyAssociationWithBrand?.InsuranceCompanies
                                      ? InsuranceCompanyAssociationWithBrand?.InsuranceCompanies
                                      : []
                                  );
                                  setInsuranceIdsData(InsuranceCompanyIds);
                                  setInsuranceNamesData(InsuranceCompanyNames);
                                }}
                              >
                                <Typography
                                  sx={{ textDecoration: "underline" }}
                                  display="inline"
                                  color="blue"
                                >
                                  [View All]
                                </Typography>
                              </div>
                            </div>
                          ) : (
                            InsuranceCompanyNames
                          )
                        ) : (
                          "N/A"
                        )}
                      </TableCell>
                      <TableCell>{API ? API : "N/A"}</TableCell>
                      <TableCell>
                        {TotalPrescribed ? TotalPrescribed : "N/A"}
                      </TableCell>
                      <TableCell>
                        {InsuranceCompanyAssociationWithBrand?.TotalSold
                          ? InsuranceCompanyAssociationWithBrand?.TotalSold
                          : "N/A"}
                      </TableCell>
                      <TableCell>
                        {InsuranceCompanyAssociationWithBrand?.RebateAmount
                          ? currencyFormat(
                              InsuranceCompanyAssociationWithBrand?.RebateAmount
                            )
                          : "N/A"}
                      </TableCell>
                      <TableCell>
                        <Button
                          title="Export"
                          onClick={() => {
                            handleUpdateTPOpen({
                              BrandName,
                              API,
                              TradePrice,
                              BrandId,
                            });
                          }}
                          variant="contained"
                          size="large"
                          sx={{
                            color: "var(--white)",
                          }}
                        >
                          <span>Request TP Update</span>
                        </Button>
                        <Button
                          title="Export"
                          onClick={() => {
                            handleExportRebateOpen(BrandId);
                          }}
                          style={{ marginLeft: "5px" }}
                          variant="contained"
                          size="large"
                          sx={{
                            color: "var(--white)",
                          }}
                        >
                          <span>Export</span>
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                }
              )
            ) : (
              <TableRow
                key={1}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {" "}
                <TableCell colSpan={7} className="no-data-found-cell">
                  {"No Record Found"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={TotalRecords}
            rowsPerPage={filterValue.Limit}
            page={filterValue.Page - 1}
            onPageChange={(e, value): any => handleChangePage(value)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={<span>Rows:</span>}
            sx={{
              ".MuiTablePagination-selectLabel, .MuiTablePagination-input": {
                fontWeight: "bold",
                color: "primary",
              },
            }}
          />
        </TableRow>
      </TableFooter>
      <InsuranceMedicineDataDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        insuranceMedicineData={insuranceMedicineData}
        insuranceIdsData={insuranceIdsData}
        insuranceNamesData={insuranceNamesData}
      />
    </>
  );
}

export default TableComponent;
