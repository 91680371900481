import { EndPoint, Env } from "../../common/constants";
import axiosI from "../../setup/axios/axios.instance";

export async function updatePasswordAction(payload: any) {
  try {
    const response = await axiosI.patch(
      `${Env.AUTH_BASE_URL}${Env.REACT_APP_VERSON}${EndPoint.UPDATE_PASSWORD}` as string,
      payload
    );

    return response;
  } catch (err: any) {
    return err;
  }
}
