import jwtDecode from "jwt-decode";
import { InsuranceCompany } from "../../pages/medicine/models";
import { JwtDecodeT } from "../../types/global.types";
import { PayType, RoutesName } from "../constants";
import showAlert from "../../common/utils/alert";

export const commonFn = () => {
  return "commonFn";
};

export const setAccessToken = (payload: string) => {
  localStorage.setItem("accessToken", payload);
};

export const setRefreshToken = (payload: string) => {
  localStorage.setItem("refreshToken", payload);
};

export const setRoleId = (payload: string) => {
  localStorage.setItem("userRoleId", payload);
};

export const setUserEmail = (payload: string) => {
  localStorage.setItem("userEmail", payload);
};

export const setUserID = (payload: string) => {
  localStorage.setItem("userId", payload);
};

export const setUserName = (payload: string) => {
  localStorage.setItem("userName", payload);
};

export const getAccessToken = () => {
  return localStorage.getItem("accessToken");
};

export const getRefreshToken = () => {
  return localStorage.getItem("refreshToken");
};

export const getRoleId = () => {
  return localStorage.getItem("userRoleId");
};

export const getUserEmail = () => {
  return localStorage.getItem("userEmail");
};

export const getUserName = () => {
  return localStorage.getItem("userName");
};

export const getUserID = () => {
  return localStorage.getItem("userId");
};

export const logout = () =>
  new Promise((resolve) => {
    localStorage.clear();
    resolve("");
  });

export const getPageTitle = (path: string) => {
  const { MY_PROFILE, MEDICINE_LIST, MANAGE_REBATE_LIST, MEDICINE_ORDERS_DETAILS, REQUEST_FORMULARY, REQUEST_FORMULARY_APPLICATIONS, FORMULARY_APPLICATIONS_DETAILS, MEDICINE_ORDERS } = RoutesName;

  if (path.includes(MEDICINE_ORDERS_DETAILS)) {
    return "Medicine Orders Details";
  }
  switch (path) {
    case MY_PROFILE:
      return "My Profile";
    case MEDICINE_LIST:
      return "Medicines";
    case MANAGE_REBATE_LIST:
      return "Manage Rebates";
    case REQUEST_FORMULARY:
      return "Request Formulary Inclusion";
    case REQUEST_FORMULARY_APPLICATIONS:
      return "Applications";
    case FORMULARY_APPLICATIONS_DETAILS:
      return "Applications Details";
    case MEDICINE_ORDERS:
    case MEDICINE_ORDERS_DETAILS:
      return "Medicine Orders";

    default:
      return "Applications Details";
  }
};

export const currencyFormat = (num: number) => {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const dataFormatForInsuranceMedicineDialog = (
  insuranceIdsData: string,
  insuranceNamesData: string,
  insuranceMedicineData: InsuranceCompany[]
) => {
  const insuranceCompanyIdsArray = insuranceIdsData.split(",");
  const insuranceCompanyNamesArray = insuranceNamesData.split(",");

  const insuranceCompanies = [];
  for (let index = 0; index < insuranceCompanyIdsArray.length; ++index) {
    const obj: any = {};
    obj["InsuranceCompanyId"] = insuranceCompanyIdsArray[index];
    obj["InsuranceCompanyName"] = insuranceCompanyNamesArray[index];
    obj["RebateAmount"] = "";
    obj["TotalSold"] = "";
    obj["TotalPrescribed"] = "";
    insuranceMedicineData.map((elem) => {
      if (obj["InsuranceCompanyId"] == elem.InsuranceCompanyId) {
        obj["RebateAmount"] = elem.RebateAmount;
        obj["TotalSold"] = elem.TotalSold;
        obj["TotalPrescribed"] = elem.TotalPrescribed;
      }
    });
    insuranceCompanies.push(obj);
  }
  return insuranceCompanies;
};

export const getPayType = (id: number) => {
  const { CHEQUE, BANKTRANSFER, MPESA } = PayType;
  switch (id) {
    case CHEQUE:
      return "Cheque";
    case BANKTRANSFER:
      return "Bank Transfer";
    case MPESA:
      return "Mpesa";
    default:
      return "N/A";
  }
};

export const decodeJwtToken = (token: string) => {
  return jwtDecode<JwtDecodeT>(token);
};

export const checkFileSize = (file: any) => {
  const fileSize = file.size;
  const fsize = Math.round(fileSize / 1024);
  console.log('fsize', fsize) // 8192')
  if (fsize >= 8192) {
    showAlert(2, "File size should be less than 8 MB");
    return true;
  }
  return false;
}

export function checkFileType(files: any) {

  let isValide = false;
  [
    ".jpg",
    ".jpeg",
    ".png",
    ".gif",
    ".pdf",
    ".zip",
    ".vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ".vnd.openxmlformats-officedocument.wordprocessingml.document",
    ".doc",
    ".xls",
    ".xlsx",
    ".msword",
  ].forEach(element => {
    if (files.fileList[0].name.includes(element)) {
      isValide = true;
    }
  });

  if (
    !isValide
  ) {
    showAlert(2, "File type should be jpg, jpeg, png, gif, pdf, doc, docx, xls, xlsx,zip");
    return true;
  }
  return false;
}

export function prePareInitialValues(res: any, applicationFormulary: any) {
  const obj = applicationFormulary
  const apiResp = res?.Data?.DrugRequestFormData
  obj.NameOfCompany = apiResp?.NameOfCompany != null && apiResp?.NameOfCompany != undefined ? apiResp?.NameOfCompany : ""
  console.log('obj.NameOfCompany out ', obj.NameOfCompany);
  if (!obj.NameOfCompany || obj.NameOfCompany == "" || obj.NameOfCompany == undefined) {
    console.log('obj.NameOfCompany in ', obj.NameOfCompany);
    obj.NameOfCompany = res?.Data?.ManufacturerName
  }
  obj.BusinessRegistrationNumber = apiResp?.BusinessRegistrationNumber != null && apiResp?.BusinessRegistrationNumber != undefined ? apiResp?.BusinessRegistrationNumber : ""
  obj.PPBPremiseRegistrationNumber = apiResp?.PPBPremiseRegistrationNumber != null && apiResp?.PPBPremiseRegistrationNumber != undefined ? apiResp?.PPBPremiseRegistrationNumber : ""
  obj.YearEstablished = apiResp?.YearEstablished != null && apiResp?.YearEstablished != undefined ? apiResp?.YearEstablished : ""
  obj.CountryId = apiResp?.CountryId != null && apiResp?.CountryId != undefined ? apiResp?.CountryId : 116
  obj.Town = apiResp?.Town != null && apiResp?.Town != undefined ? apiResp?.Town : ""
  obj.County = apiResp?.County != null && apiResp?.County != undefined ? apiResp?.County : ""
  obj.PostalAddress = apiResp?.PostalAddress != null && apiResp?.PostalAddress != undefined ? apiResp?.PostalAddress : ""
  obj.OfficialEmail = apiResp?.OfficialEmail != null && apiResp?.OfficialEmail != undefined ? apiResp?.OfficialEmail : ""
  obj.TelephoneNumber = apiResp?.TelephoneNumber != null && apiResp?.TelephoneNumber != undefined ? apiResp?.TelephoneNumber : ""
  obj.AlternativeContact = apiResp?.AlternativeContact != null && apiResp?.AlternativeContact != undefined ? apiResp?.AlternativeContact : ""
  obj.CompanyWebsite = apiResp?.CompanyWebsite != null && apiResp?.CompanyWebsite != undefined ? apiResp?.CompanyWebsite : ""
  obj.NumberOfProductsRegisteredWithThePPBToDate = apiResp?.NumberOfProductsRegisteredWithThePPBToDate != null && apiResp?.NumberOfProductsRegisteredWithThePPBToDate != undefined ? apiResp?.NumberOfProductsRegisteredWithThePPBToDate : ""
  obj.ProductsToBeIncludedInTheHospitalFormulary = apiResp?.ProductsToBeIncludedInTheHospitalFormulary != null && apiResp?.ProductsToBeIncludedInTheHospitalFormulary != undefined ? apiResp?.ProductsToBeIncludedInTheHospitalFormulary[0] : []
  obj.NatureOfBusiness = apiResp?.NatureOfBusiness != null && apiResp?.NatureOfBusiness != undefined ? apiResp?.NatureOfBusiness : ""
  obj.natureOfBusinessOther = apiResp?.natureOfBusinessOther != null && apiResp?.natureOfBusinessOther != undefined ? apiResp?.natureOfBusinessOther : ""
  if (obj.NatureOfBusiness) {
    const natureOfBusinessOptions = ['Pharmaceutical importer', 'Pharmaceutical manufacturer', 'other'];
    const isOther = natureOfBusinessOptions.filter((item) => {
      if (item == obj.NatureOfBusiness) {
        return item
      }
    })

    if (isOther.length == 0) {
      obj.natureOfBusinessOther = obj.NatureOfBusiness
    }
  }
  obj.InsuranceCompanies = apiResp?.InsuranceCompanies != null && apiResp?.InsuranceCompanies != undefined ? apiResp?.InsuranceCompanies : []
  obj.TradePricePerUnit = apiResp?.TradePricePerUnit != null && apiResp?.TradePricePerUnit != undefined ? apiResp?.TradePricePerUnit : 0
  obj.certificateofregistration = apiResp?.certificateofregistration != null && apiResp?.certificateofregistration != undefined ? apiResp?.certificateofregistration : ""
  obj.taxcompliancecertificate = apiResp?.taxcompliancecertificate != null && apiResp?.taxcompliancecertificate != undefined ? apiResp?.taxcompliancecertificate : ""
  obj.businesspermit = apiResp?.businesspermit != null && apiResp?.businesspermit != undefined ? apiResp?.businesspermit : ""
  obj.certificateofregistrationpremises = apiResp?.certificateofregistrationpremises != null && apiResp?.certificateofregistrationpremises != undefined ? apiResp?.certificateofregistrationpremises : ""
  obj.certificatefromthePPB = apiResp?.certificatefromthePPB != null && apiResp?.certificatefromthePPB != undefined ? apiResp?.certificatefromthePPB : ""
  obj.Clients = apiResp?.Clients != null && apiResp?.Clients != undefined ? apiResp?.Clients : []
  obj.otherClient = apiResp?.otherClient != null && apiResp?.otherClient != undefined ? apiResp?.otherClient : ""
  if (obj.Clients) {
    const natureOfBusinessOptions = ['Hospitals', 'Pharmaceutical Distributors', 'Pharmaceutical Retailers', 'Institutions'];
    const isOtherClient = obj?.Clients?.filter((item: any) => {
      if (!natureOfBusinessOptions.includes(item)) {
        return item
      }
    })

    if (isOtherClient.length != 0) {
      obj.otherClient = isOtherClient[0]
    }
  }


  return obj
}


export function preparePayload(values: any) {
  delete values.otherClient;
  delete values.natureOfBusinessOther;
  delete values.certificateofregistration;
  delete values.taxcompliancecertificate;
  delete values.businesspermit;
  delete values.certificateofregistrationpremises;
  delete values.certificatefromthePPB;
  if (!values.NameOfCompany) {
    delete values.NameOfCompany;
  }
  if (!values.BusinessRegistrationNumber) {
    delete values.BusinessRegistrationNumber;
  }
  if (!values.PPBPremiseRegistrationNumber) {
    delete values.PPBPremiseRegistrationNumber;
  }
  if (!values.YearEstablished) {
    delete values.YearEstablished;
  }
  if (!values.CountryId) {
    delete values.CountryId;
  }
  if (!values.Town) {
    delete values.Town;
  }
  if (!values.County) {
    delete values.County;
  }
  if (!values.PostalAddress) {
    delete values.PostalAddress;
  }
  if (!values.OfficialEmail) {
    delete values.OfficialEmail;
  }
  if (!values.TelephoneNumber) {
    delete values.TelephoneNumber;
  }
  if (!values.AlternativeContact) {
    delete values.AlternativeContact;
  }
  if (!values.CompanyWebsite) {
    delete values.CompanyWebsite;
  }
  if (!values.NumberOfProductsRegisteredWithThePPBToDate) {
    delete values.NumberOfProductsRegisteredWithThePPBToDate;
  }
  if (!values.ProductsToBeIncludedInTheHospitalFormulary) {
    delete values.ProductsToBeIncludedInTheHospitalFormulary;
  }
  if (!values.NatureOfBusiness) {
    delete values.NatureOfBusiness;
  }
  if (!values.natureOfBusinessOther) {
    delete values.natureOfBusinessOther;
  }
  if (!values.certificateofregistration) {
    delete values.certificateofregistration;
  }
  if (!values.taxcompliancecertificate) {
    delete values.taxcompliancecertificate;
  }
  if (!values.businesspermit) {
    delete values.businesspermit;
  }
  if (!values.certificateofregistrationpremises) {
    delete values.certificateofregistrationpremises;
  }

}

export function NatureOfBusinessOptions() {
  return ['Pharmaceutical importer', 'Pharmaceutical manufacturer', 'other'];
}

export function Clients() {
  return ['Hospital', 'Pharmaceutical Distributors', 'Pharmaceutical Retailers', 'Institutions', 'other'];
}

export function generateId() {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!';
  const length = 10;

  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  const date = new Date().toISOString().split('T')[0].replace(/-/g, '');
  return `${date}_${result}`;
}

export function getInsuranceCompanyIds(insuranceCompanyId: any, insuranceCompany: any) {
  //function to get ids of insurance companies from the insurance company names
  const insuranceCompanyIds = insuranceCompanyId?.map((item: any) => {
    const insuranceCompanyObj = insuranceCompany?.filter((insurance: any) => {
      if (insurance.Name == item.InsuranceCompanyName) {
        return insurance;
      }
    });
    return insuranceCompanyObj[0]?.InsuranceCompanyId;
  });
  return insuranceCompanyIds;
}

//function to get one key value from an array of objects into an comma separated string
export function getInsuranceIds(insuranceCompanyId: any) {
  const insuranceIds = insuranceCompanyId?.map((item: any) => {
    return item.InsuranceCompanyId;
  });
  return insuranceIds;
}

//function to push name in array if condition is true

export function getInsuranceCompanyNamesByStatus(insuranceCompanies: any, status: any) {
  const insuranceCompanyNames = insuranceCompanies?.map((item: any) => {
    if (item.DrugRequestFormInsuranceStatus == status) {
      return item.InsuranceCompanyName;
    }
  });
  return removeUndefinedFromArray(insuranceCompanyNames).join(', ');
}

//remove undefined values from array
export function removeUndefinedFromArray(array: any) {
  const newArray = array?.filter((item: any) => {
    if (item != undefined) {
      return item;
    }
  });
  return newArray;
}



