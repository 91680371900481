import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReactFileReader from "react-file-reader";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { uploadToS3 } from '../../common/utils/upload';
import showAlert from "../../common/utils/alert";
import { Theme, Button, Box, Avatar, Grid, TextField, FormControlLabel } from "@mui/material";
import { checkFileSize, checkFileType, generateId } from "../../common/utils";
import b64toBlob from 'b64-to-blob';

import {
    globalLoaderFalse,
    globalLoaderTrue,
} from "../../redux/slice/global.loader.slice";


function FileBrowse(props: any) {
    const dispatch = useDispatch();
    const isdisabled = props.disabled;

    const b64ToBlob = (data: any, type: any) => {
        const contentType = type;
        var blob = b64toBlob(data, contentType);
        return blob;
    }
    const uploadFile = (files: any, fileType: string) => {

        
        if (checkFileSize(files.fileList[0])) return;
        if (checkFileType(files)) return;
        
        if (files.base64) {          
            let fileExt = files.fileList[0].name;
			var n = fileExt.lastIndexOf(".");
			fileExt = fileExt.substring(n + 1);
			
			const fileName = generateId() + "_fromu." + fileExt;
            
            const actualFIle = b64ToBlob(files.base64.split(",")[1], files.fileList[0].type)
            dispatch(globalLoaderTrue());
            uploadToS3(actualFIle, fileName, files.fileList[0].type).then((res: any) => {
                props.getUploadedPath(res, props.nameOfFile)
                dispatch(globalLoaderFalse());
            }).catch((err) => {
                console.log('file update err', err)
            });
        }
    };
    return (
        <div className="mr-10">
            <ReactFileReader
                handleFiles={(files: any) => {
                    uploadFile(files, "pres");
                }}
                disabled={isdisabled}
                base64={true}
                multipleFiles={false}
                fileTypes={[".jpg",
                    ".jpeg",
                    ".png",
                    ".gif",
                    ".pdf",
                    ".xls",
                    ".xlsx",
                    ".zip",
                    ".vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    ".vnd.openxmlformats-officedocument.wordprocessingml.document",
                    ".doc",
                    ".msword"]}
            >
                <Button
                    className="btn"
                    title="Please upload  image"
                    sx={{ padding: "0px", margin: "3px" }}
                >
                    <Avatar
                        variant="square"
                        style={{ width: '100%', height: '100%', background: 'white', boxShadow: "0px 1px 2px 1px rgba(0,0,0,0.2)" }}
                    >
                        <UploadFileIcon color="primary" fontSize="large" />
                    </Avatar>
                </Button>
            </ReactFileReader>
        </div>
    );
}
export default FileBrowse;
