import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import logoImg from "../../assets/images/logo.svg";
import loginBannerImg from "../../assets/images/auth-banner.png";
import { Form, Formik } from "formik";
import FORM_FIELD from "../../validations/form-field";
import SCHEMA from "../../validations/schema";
import { useDispatch } from "react-redux";
import {
  globalLoaderFalse,
  globalLoaderTrue,
} from "../../redux/slice/global.loader.slice";
import { forgotPasswordAction } from "./action";
import showAlert from "../../common/utils/alert";
import { Link, useNavigate } from "react-router-dom";
import { ResponseCode, RoutesName } from "../../common/constants";
import { useEffect, useState } from "react";
import { getAccessToken } from "../../common/utils";

function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sentLink, setSentLink] = useState(false);

  useEffect(() => {
    if (getAccessToken()) {
      navigate(RoutesName.MANAGE_REBATE_LIST);
    }
  }, []);

  const handleSubmit = (values: any, action: any) => {
    const payload = {
      PrincipalIdentifierValue: values.email,
    };

    dispatch(globalLoaderTrue());
    forgotPasswordAction(payload)
      .then((res: any) => {
        dispatch(globalLoaderFalse());
        action.setSubmitting(false);
        if (res?.response?.status !== ResponseCode.NOT_ACCEPTABLE) {
          showAlert(
            1,
            "Link has been sent to your registered email successfully."
          );
          setSentLink(true);
        }
      })
      .catch((err) => {
        dispatch(globalLoaderFalse());
        action.setSubmitting(false);
      });
  };
  return (
    <div className="page-login">
      <div className="login-content">
        <div className="left-portion">
          <div className="banner-img-cover">
            <img alt="" src={loginBannerImg} />
          </div>
          <img alt="" className="logo-image" src={logoImg} />
        </div>
        <div className="right-portion">
          <img alt="" className="logo-image for-mobile" src={logoImg} />
          <h2 className="color-blue bold mb-20">Forgot Password</h2>
          <p>
            Please enter the registered email address to receive the password
            link
          </p>
          {sentLink ? (
            <div>
              <p className="mt-30 color-green">
                Forgot password link has been sent to your registered email id.
                Please check your email and follow steps to reset your password.
              </p>
              <div className="right mt-10">
                <Link to={RoutesName.LOGIN}>Back to login?</Link>
              </div>
            </div>
          ) : (
            <Formik
              initialValues={FORM_FIELD.forgotPassword}
              validationSchema={SCHEMA.forgotPassword}
              onSubmit={handleSubmit}
            >
              {({
                isSubmitting,
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
              }) => {
                return (
                  <Form className="w-100">
                    <Grid container spacing={6}>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          label="Email Address"
                          variant="outlined"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          error={!!errors.email && touched.email}
                          helperText={
                            touched.email && !!errors.email && errors.email
                          }
                        />
                      </Grid>
                    </Grid>
                    <div className="right mt-10">
                      <Link to={RoutesName.LOGIN}>Back to login?</Link>
                    </div>
                    <div className="btn-cover mt-30">
                      <button type="submit" className="btn btn-primary">
                        {isSubmitting ? "Loading..." : "Proceed"}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
