import { useEffect, useState } from "react";
import {
  Theme,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Input,
  Button,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch } from "react-redux";
import { makeStyles, createStyles } from "@mui/styles";
import "../../assets/styles/table.css";
import "../../assets/styles/listing-filters.css";

import FilterComponent from "./filter";
import FileBrowse from "../../components/file-browse/fileBrowse";
import { useParams } from "react-router-dom";
import { RoutesName, Env } from "../../common/constants";
import { useNavigate } from "react-router-dom";

import { IFilter } from "./models";
import {
  globalLoaderFalse,
  globalLoaderTrue,
} from "../../redux/slice/global.loader.slice";
import { getMedicineList } from "../../redux/slice/medicine.slice";
import Modal from "@mui/material/Modal";

import { styled } from "@mui/system";
import { submitQuote, getOrderDetailsById } from "./action";

import "./medicine.css";
import showAlert from "../../common/utils/alert";

export const formulation = [
  { key: "Tablet", value: "1", text: "Tablet" },
  { key: "Capsule", value: "2", text: "Capsule" },
  { key: "Vial", value: "3", text: "Vial" },
  // { key: "Teaspoon", value: "4", text: "Teaspoon" },
  { key: "Drop", value: "5", text: "Drop" },
  { key: "Lotion", value: "6", text: "Lotion application" },
  { key: "Gel", value: "7", text: "Gel application" },
  { key: "Spray", value: "8", text: "Spray" },
  { key: "Cream", value: "9", text: "Cream application" },
  { key: "Ointment", value: "10", text: "Ointment application" },
  // { key: "Tablespoon", value: "11", text: "Tablespoon" },
  { key: "Suppository", value: "12", text: "Suppository" },
  { key: "Mouth rinse", value: "13", text: "Mouth rinse" },
  { key: "Shampoo", value: "14", text: "Shampoo application" },
  { key: "Puff", value: "15", text: "Puff" },
  { key: "Lozenge", value: "16", text: "Lozenge" },
  { key: "Sachet", value: "17", text: "Sachet" },
  { key: "Gram", value: "18", text: "Gram" },
  { key: "Mls", value: "19", text: "Mls" },
  {
    key: "International Units (IU)",
    value: "20",
    text: "International Units (IU)",
  },
  { key: "Strip", value: "21", text: "Strip" },
  { key: "Piece", value: "22", text: "Piece" },
  { key: "Milligram", value: "23", text: "Milligram" },
];

const formStatus = [
  { key: "1", text: "Pending Quotation", value: "Pending Quotation" },
  { key: "2", text: "Quote Submitted", value: "Quote Submitted" },
  { key: "3", text: "Prepare order", value: "Prepare order" },
  { key: "4", text: "Order completed", value: "Order completed" },
  { key: "5", text: "Order Cancelled", value: "Order Cancelled" },
  { key: "6", text: "Order Declined", value: "Order Declined" },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    csv: {
      color: "var(----light-green)",
    },
  })
);

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const style = {
  width: 440,
  bgcolor: "background.paper",
  boxShadow: "0px 1px 3px 2px rgba(0,0,0,0.2)",
  borderRadius: "10px",
  p: 5,
};

function OrderDetails() {
  const { MEDICINE_ORDERS } = RoutesName;
  let total_price = 0;
  const classes = useStyles();
  const { formId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [filter, setFilter] = useState(false);

  const [drugFormID, setFormID] = useState<any>("");
  const [orderDrugs, setOrderDrugs] = useState<any>([]);

  const [Status, setStatus] = useState<any>([]);

  const [filterValue, setFilterValue] = useState<IFilter>({
    Page: 1,
    Limit: 10,
    InsuranceCompanyId: "",
    FromDate: "",
    ToDate: "",
    Search: "",
  });

  useEffect(() => {
    setFormID(formId);

    dispatch(globalLoaderTrue());
    getOrderDetailsById(formId).then((res: any) => {
      if (res) {
        const data = res?.Data?.MedicineOrders;
        setOrderDrugs(data);

        dispatch(globalLoaderFalse());
      }
    });
  }, [formId]);
  useEffect(() => {
    dispatch(getMedicineList(filterValue) as any);
  }, [filterValue, dispatch]);

  const applyFilter = (values: any) => {
    setFilterValue((prev: any) => ({
      ...prev,
      Page: 1,
      FromDate: values.fromDate,
      ToDate: values.toDate,
      InsuranceCompanyId: values.insuranceCompanyId,
    }));
  };

  const [state, setState] = useState({
    gilad: true,
    jason: false,
    antoine: false,
  });

  const { gilad, jason, antoine } = state;
  const error = [gilad, jason, antoine].filter((v) => v).length !== 2;

  const UpdatePricesArr = (val: any, DrugId: any) => {
    const integarRegex = /^[\d.]+$/;

    if (val.target.value === "" || integarRegex.test(val.target.value)) {
      const newArr = orderDrugs?.map((item: any) => {
        if (item?.DrugId == DrugId) {
          item.Price = val.target.value;
        }
        return item;
      });

      setOrderDrugs(newArr);
    }
  };

  const SubmitQuote = () => {
    let payload: any = [];
    let priceItem: any = [];

    let priceNotSubmitted = false;
    orderDrugs?.map((item: any) => {
      if (item.Price == "0" || item.Price == 0) {
        priceNotSubmitted = true;
      } else {
        let elem = {
          DrugId: item.DrugId,
          Price: item.Price,
          OrderId: formId,
        };
        priceItem.push(elem);
      }
    });

    if (priceNotSubmitted) {
      showAlert(2, "Please enter price for all drugs");
      return;
    }

    dispatch(globalLoaderTrue());
    submitQuote({ drugsData: priceItem })
      .then((res: any) => {
        if (res?.status === 200) {
          dispatch(globalLoaderFalse());
          showAlert(1, "Quote submitted successfully");
          navigate(`${MEDICINE_ORDERS}`);
        } else {
          dispatch(globalLoaderFalse());
          showAlert(2, "Something went wrong");
        }
      })
      .catch((err: any) => {});
  };

  const getFormulation = (form_id: any) => {
    let form = formulation.find((item: any) => item.value == form_id);
    return form?.text;
  };

  const displayQuantityMeasuring = (
    mesuring_qatagory: any,
    quantity_per_category: any,
    form_id: any,
    quantity_measuring: any
  ) => {
    let quantity_measuring_val;
    if ((!mesuring_qatagory || mesuring_qatagory == null) && form_id == null) {
      quantity_measuring_val = quantity_measuring;
    } else {
      if (mesuring_qatagory == null || mesuring_qatagory == "") {
        quantity_measuring_val = getFormulation(form_id);
      } else {
        quantity_measuring_val =
          mesuring_qatagory +
          "(" +
          quantity_per_category +
          " " +
          getFormulation(form_id) +
          ")";
      }
    }
    quantity_measuring_val = quantity_measuring_val
      ? quantity_measuring_val
      : "N/A";
    return quantity_measuring_val;
  };

  return (
    <div className="page-drug-formulary bg-white">
      <Grid container sx={{ mb: "20px" }} spacing={3}>
        {filter ? (
          <Grid item md={12} style={{ width: "100%" }}>
            <FilterComponent applyFilter={applyFilter} />
          </Grid>
        ) : (
          " "
        )}
      </Grid>
      <div>
        <div style={{ textAlign: "right", paddingBottom: "20px" }}>
          {" "}
          <b>Order Status</b> :{" "}
          {orderDrugs?.length > 0
            ? formStatus[orderDrugs[0]["OrderStatus"] - 1].text
            : ""}
        </div>
        {orderDrugs?.length > 0 && (orderDrugs[0]["OrderStatus"] == 5 ||
          orderDrugs[0]["OrderStatus"] == 6) && (
          <div style={{ textAlign: "left", paddingBottom: "20px" }}>
            {orderDrugs[0]?.OrderStatus == 5 ? "Cancellation " : "Declined"} Reason :{" "}
            <span style={{ color: "red" }}>{orderDrugs[0]?.Reason}</span>
          </div>
        )}
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="medium">
            <TableHead>
              <TableRow>
                <TableCell>Name of the Brand</TableCell>
                <TableCell>API of the Brand</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Quantity Measuring</TableCell>
                <TableCell>Price(KES)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderDrugs?.length > 0 ? (
                orderDrugs?.map((index: any) => {
                  if (index?.Price !== "") {
                    total_price = parseFloat(index?.Price) + total_price;
                  }

                  return (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      color={Status === 1 ? "primary.main" : "text.primary"}
                    >
                      <TableCell>
                        {index?.brand_name ? index?.brand_name : "N/A"}
                      </TableCell>
                      <TableCell>
                        {index?.drug_name ? index?.drug_name : "N/A"}
                      </TableCell>
                      <TableCell>{index?.Quantity}</TableCell>
                      <TableCell>
                        {displayQuantityMeasuring(
                          index?.quantity_measuring_category,
                          index?.quantity_per_category,
                          index?.form_id,
                          index?.quantity_measuring
                        )}
                      </TableCell>
                      <TableCell>
                        {
                          <div
                            style={{
                              textDecorationColor: "blue",
                              textDecorationLine: "underline",
                            }}
                          >
                            <Input
                              readOnly={index?.OrderStatus > 2 ? true : false}
                              type="text"
                              value={index?.Price}
                              name="TradePricePerUnit"
                              onChange={(val) =>
                                UpdatePricesArr(val, index?.DrugId)
                              }
                            />
                          </div>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow
                  key={1}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {" "}
                  <TableCell colSpan={7} className="no-data-found-cell">
                    {"No Record Found"}
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell
                  colSpan={5}
                  style={{ textAlign: "end", padding: "205px" }}
                >
                  <b>Total:</b> {total_price.toFixed(2)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TableFooter>
          <br />
          <>
            <Button
              style={{
                marginRight: "15px",
                color: "white",
                backgroundColor: "gray",
              }}
              onClick={() => navigate(`${MEDICINE_ORDERS}`)}
              color="primary"
            >
              {" "}
              Cancel
            </Button>
            {orderDrugs?.length > 0 && orderDrugs[0]["OrderStatus"] > 2
              ? ""
              : orderDrugs?.length > 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={SubmitQuote}
                  >
                    {orderDrugs?.length > 0 &&
                    (orderDrugs[0]["OrderStatus"] == 1 ||
                      orderDrugs[0]["OrderStatus"] == 2)
                      ? orderDrugs[0]["OrderStatus"] == 1
                        ? "Submit Quote"
                        : "Update Quote"
                      : ""}
                  </Button>
                )}
          </>
        </TableFooter>
      </div>
    </div>
  );
}

export default OrderDetails;
