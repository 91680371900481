import { Navigate } from "react-router-dom";
import { RoutesName } from "../../common/constants/RoutesName";
import i2mg from "../../assets/images/2.jpg";
import i9mg from "../../assets/images/9.jpg";
import i10mg from "../../assets/images/10.png";
import i11mg from "../../assets/images/11.png";
import i12mg from "../../assets/images/12.png";
import i13mg from "../../assets/images/13.png";
import i14mg from "../../assets/images/14.jpeg";
import i15mg from "../../assets/images/15.png";
import i16mg from "../../assets/images/16.png";
import i17mg from "../../assets/images/17.png";
import i18mg from "../../assets/images/18.png";
import insuranceImg from "../../assets/svg/currency.svg";
import insuranceImg2 from "../../assets/svg/currency.svg";
import insuranceImg3 from "../../assets/svg/payment.svg";
import { getAccessToken } from "../../common/utils";
import Header from "../../components/header/header";
import { Grid } from "@mui/material";

function PrivateRoute({ children }: any) {
  const { LOGIN } = RoutesName;

  return (
    <>
      {getAccessToken() ? (
        <div>
          <Header />
          <div className="main-wrapper">
            <main>
              {children}
            </main>
            <aside>
              <Grid container spacing={{xs: .5, sm: 2}}>
                <Grid item xs={12} sm={6}>
                  <figure><img src={i9mg} /></figure>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <figure><img src={i10mg} /></figure>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <figure><img src={i11mg} /></figure>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <figure><img src={i12mg} /></figure>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <figure><img src={i13mg} /></figure>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <figure><img src={i14mg} /></figure>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <figure><img src={i15mg} /></figure>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <figure><img src={i16mg} /></figure>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <figure><img src={i17mg} /></figure>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <figure><img src={i18mg} /></figure>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <figure><img src={insuranceImg2} /></figure>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <figure><img src={insuranceImg3} /></figure>
                </Grid> */}

              </Grid>
            </aside>
          </div>
        </div>
      ) : (
        <Navigate to={LOGIN} />
      )}
    </>
  );
}

export default PrivateRoute;
