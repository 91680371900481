import {
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  Typography,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { Button } from "@mui/material";
import { Formik, Form } from "formik";
import SCHEMA from "../../validations/schema";
import FORM_FIELD from "../../validations/form-field";
import { useEffect, useState } from "react";
import { getInsuranceCompanyList } from "./action";
import { EndPoint, Env } from "../../common/constants";
import useDebounce from "../../hooks";
import { useDispatch } from "react-redux";
import {
  globalLoaderFalse,
  globalLoaderTrue,
} from "../../redux/slice/global.loader.slice";
import axiosI from "../../setup/axios/axios.instance";

const OrderStatus = [
  { key: "1", text: "Pending Quotation", value: "Pending Quotation" },
  { key: "2", text: "Quote Submitted", value: "Quote Submitted" },
  { key: "3", text: "Prepare order", value: "Prepare order" },
  { key: "4", text: "Order completed", value: "Order completed" },
  { key: "5", text: "Order Cancelled", value: "Order Cancelled" },
  { key: "6", text: "Order Declined", value: "Order Declined" },
];

function FilterComponent({ applyFilter }: any) {
  const dispatch = useDispatch();
  const [pharmacyId, setPharmacy] = useState<any>("");
  const [options, setOptions] = useState<any[]>([]);
  const [initialValue, setInitialValue] = useState<any>(
    FORM_FIELD.medicineOrderFilter
  );
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState<any>({name: "", id: ""});
  const [formValues, setFormValues] = useState<any>(
    FORM_FIELD.medicineOrderFilter
  );

  const debouncedSearchTerm: string = useDebounce<string>(
    inputValue && inputValue,
    500
  );
  const handleClear = () => {
    setPharmacy("");
    setValue({name: "", id: ""})
    setFormValues(FORM_FIELD.medicineOrderFilter);
    applyFilter(FORM_FIELD.medicineOrderFilter);
  };

  const handleFilter = (values: any) => {
    if (
      values.fromDate !== "" ||
      values.toDate !== "" ||
      values.status ||
      values.pharmacyId
    )
      setFormValues(values);
    applyFilter(values, pharmacyId);
  };
  console.log("FORM_FIELD.medicineOrderFilter", formValues);

  const getPharmacyList = (
    debouncedSearchTerm: any = "",
    actives: any = false
  ) => {
    if (debouncedSearchTerm == "undefined") {
      debouncedSearchTerm = "";
    }

    let Page = 1;
    let Limit = 10;
    let Search = debouncedSearchTerm;
    const queryString = `?IsManufactureSearch=1&Page=${Page}&Limit=${Limit}&Search=${Search}`;
    // dispatch(globalLoaderTrue());
    const response: any = axiosI
      .get(`${EndPoint.PHARMACY_LIST}${queryString}`)
      .then((res: any) => {
        if (res?.data?.IsSuccess) {
          const results: any = [...res?.data?.Data?.Brands];
          if (actives) {
            let newOptions: any = [];
            let newResults: any = [];
            for (let ele of results) {
              newResults.push({
                ...ele,
                searchDrug: debouncedSearchTerm,
              });
            }
            if (results) {
              newOptions = [...newOptions, ...newResults];
            }
            setOptions(newOptions);
          }
        }
        // dispatch(globalLoaderFalse());
      })
      .catch((err: any) => {
        // dispatch(globalLoaderFalse());
      });
  };

  useEffect(() => {
    let actives = true;
    if (debouncedSearchTerm === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }
    if (debouncedSearchTerm.length > 0) {
      getPharmacyList(debouncedSearchTerm, actives);
    }

    return () => {
      actives = false;
    };
  }, [value, debouncedSearchTerm]);

  useEffect(() => {
    //getPharmacyList();
  }, []);

  useEffect(() => {
    applyFilter(formValues, pharmacyId);
  }, [pharmacyId]);

  return (
    <Formik
      initialValues={FORM_FIELD.medicineOrderFilter}
      validationSchema={SCHEMA.medicineOrderFilter}
      onSubmit={handleFilter}
      enableReinitialize
    >
      {({ handleChange, handleBlur, values, errors }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Typography>From Date</Typography>
              <FormControl fullWidth>
                <TextField
                  name="fromDate"
                  type="date"
                  size="small"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.fromDate}
                  error={!!errors.fromDate}
                  helperText={!!errors.fromDate && errors.fromDate}
                  inputProps={{ max: values.toDate }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Typography>To Date</Typography>
              <FormControl fullWidth>
                <TextField
                  name="toDate"
                  type="date"
                  size="small"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.toDate}
                  error={!!errors.toDate}
                  helperText={!!errors.toDate && errors.toDate}
                  inputProps={{ min: values.fromDate }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <FormControl
                fullWidth
                sx={{ marginTop: { xs: "10px", md: "24px" } }}
                className="insurance-dropdown"
              >
                <InputLabel id="insurance-company-id-select-label">
                  Select Status
                </InputLabel>
                <Select
                  name="status"
                  labelId="insurance-company-id-select-label"
                  id="insurance-company-id"
                  value={values.status}
                  label="Select Status"
                  size="small"
                  onChange={handleChange}
                >
                  {OrderStatus.length > 0
                    ? OrderStatus?.map((item: any) => (
                        <MenuItem value={item.key} className="justify-startt">
                          <h4>{item.value}</h4>
                        </MenuItem>
                      ))
                    : ""}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={2}>
              <FormControl
                fullWidth
                sx={{ marginTop: { xs: "10px", md: "24px" } }}
                className="insurance-dropdown"
              >
                <InputLabel id="insurance-company-id-select-label">
                  {/* Select Pharmacy */}
                </InputLabel>

                <Autocomplete
                  value={values ? value : null}
                  size="small"
                  fullWidth
                  placeholder="Search pharmacy"
                  // filterOptions={filterOptions}

                  onChange={(event: any, newValue: any) => {
                    setPharmacy(newValue?.id);
                    setOptions(newValue ? [newValue, ...options] : options);
                    setValue(newValue);

                    setInitialValue({
                      ...initialValue,
                      pharmacyId: newValue.name,
                    });
                    if (newValue === null) {
                      setInitialValue(newValue.id);
                    }
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  getOptionLabel={(option: any) => {
                    return `${option.name}`;
                  }}
                  id="controllable-states-demo"
                  options={options}
                  renderOption={(props, option: any) => {
                    return (
                      <>
                        <li {...props} key={Math.random()}>
                          <p>{option.name} </p>
                        </li>
                      </>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="pharmacyId"
                      InputProps={{
                        ...params.InputProps,
                      }}
                      placeholder="Select Pharmacy"
                      error={Boolean(
                        errors.pharmacyId &&
                          // touched.pharmacyId &&
                          errors.pharmacyId
                      )}
                      helperText={Boolean(
                        errors.pharmacyId &&
                          // touched.pharmacyId &&
                          errors.pharmacyId
                      )}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={12} lg={4}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="medium"
                sx={{
                  fontWeight: "bold",
                  minWidth: "auto",
                  marginTop: { md: "0px", lg: "26px" },
                  marginRight: "16px",
                }}
              >
                Apply
              </Button>
              <Button
                variant="outlined"
                color="primary"
                type="reset"
                size="medium"
                sx={{
                  fontWeight: "bold",
                  minWidth: "auto",
                  marginTop: { md: "0px", lg: "26px" },
                }}
                onClick={handleClear}
                disabled={
                  values.toDate === "" &&
                  values.fromDate === "" &&
                  values.status === ""
                }
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default FilterComponent;
