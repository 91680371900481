import { EndPoint, Env } from "../../common/constants";
import axiosI from "../../setup/axios/axios.instance";

export async function logoutAction(payload: any) {
  try {
    const response = await axiosI.post(
      `${Env.AUTH_BASE_URL}${Env.REACT_APP_VERSON}${EndPoint.LOGOUT}` as string,
      payload
    );
    localStorage.clear();
    
    return response;
  } catch (err) {
    return err;
  }
}
