import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EndPoint } from "../../common/constants";
import showAlert from "../../common/utils/alert";
import { IFilter } from "../../pages/medicine/models";
import axiosI from "../../setup/axios/axios.instance";
import {drugs} from "../redux-state";
import { globalLoaderFalse, globalLoaderTrue } from "./global.loader.slice";

// First, create the thunk
export const getDrugsFormRequestList = createAsyncThunk(
  "fetchDrugsFormRequestList",
  async (filterValue: IFilter, { rejectWithValue, dispatch }) => {
    const { Page, Limit, InsuranceCompanyId, FromDate, ToDate, Search } =
      filterValue;
    const queryString = `?Page=${Page}&Limit=${Limit}&FromDate=${FromDate}&ToDate=${ToDate}&Search=${Search}`;
    dispatch(globalLoaderTrue());
    try {
      const response = await axiosI.get(
        `${EndPoint.FORMULARY_INCLUSION}/drug-request-form-list/${queryString}`
      );
      dispatch(globalLoaderFalse());
      return response.data?.Data;
    } catch (err: any) {
      dispatch(globalLoaderFalse());
      return rejectWithValue(err?.errors[0]);
    }
  }
);

const initialState = {
  entities: drugs,
  loading: "idle",
};

const drugsSlice = createSlice({
  name: "drugs",
  initialState,
  reducers: {
    //
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getDrugsFormRequestList.fulfilled, (state, action) => {
      state.entities = action.payload;
    });
  },
});

export default drugsSlice.reducer;
