import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, createStyles } from "@mui/styles";
import { currencyFormat } from "../../common/utils";
import { RootState } from "../../setup/store/store";
import InsuranceMedicineDataDialog from "./insurance-medicine-data-dialog";
import { InsuranceCompany, OrderData } from "./models";
import { fetchRebateDetailsCsvData } from "./action";
import { useNavigate } from "react-router-dom";
import {
  globalLoaderFalse,
  globalLoaderTrue,
} from "../../redux/slice/global.loader.slice";
import { Box } from "@mui/system";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/system";
import { RoutesName } from "../../common/constants";
import moment from "moment";
const formStatus = [
  { key: "1", text: "Pending Quotation", value: "Pending Quotation" },
  { key: "2", text: "Quote Submitted", value: "Quote Submitted" },
  { key: "3", text: "Prepare order", value: "Prepare order" },
  { key: "4", text: "Order completed", value: "Order completed" },
  { key: "5", text: "Order Cancelled", value: "Order Cancelled" },
  { key: "6", text: "Order Declined", value: "Order Declined" },
];

const getStatus = (status: any) => {
  return formStatus.find((item) => item.key === status.toString())?.text;
};

const useStyles = makeStyles(() =>
  createStyles({
    csv: {
      color: "var(----light-green)",
    },
  })
);

const StyledModal: any = styled(Modal)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const style = {
  width: 440,
  bgcolor: "#fff",
  boxShadow: "0px 1px 3px 2px rgba(0,0,0,0.2)",
  borderRadius: "10px",
  p: 5,
};

function TableComponent({
  filterValue,
  handleChangePage,
  handleChangeRowsPerPage,
}: any) {
  const { MedicineOrders, TotalRecords } = useSelector(
    (state: RootState) => state.medicineOrders?.entities
  );

  const { MEDICINE_ORDERS_DETAILS } = RoutesName;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [applicationId, setApplicationId] = useState<number>(0);
  const [openExportRebate, setOpenExportRebate] = useState(false);
  const [insuranceMedicineData, setInsuranceMedicineData] = useState<
    InsuranceCompany[]
  >([]);
  const [insuranceIdsData, setInsuranceIdsData] = useState<string>("");
  const [insuranceNamesData, setInsuranceNamesData] = useState<string>("");

  const handleExportRebateDetailsDownload = () => {
    dispatch(globalLoaderTrue());
    const fileName = `Rebate-details-${new Date().toLocaleString("default", {
      month: "long",
    })}-${new Date().getDate()}.csv`;

    fetchRebateDetailsCsvData(filterValue, applicationId)
      .then((res: any) => {
        dispatch(globalLoaderFalse());
        if (res !== undefined) {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;

          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err: any) => {
        dispatch(globalLoaderFalse());
      });
    setOpenExportRebate(false);
  };

  const handleExportRebateClose = () => {
    setApplicationId(0);
    setOpenExportRebate(false);
    dispatch(globalLoaderFalse());
  };

  const handleExportRebateOpen = (applicationId: number) => {
    setApplicationId(applicationId);
    setOpenExportRebate(true);
  };

  const viewApplicationDetails = (applicationId: number) => {
    navigate(`${MEDICINE_ORDERS_DETAILS}/${applicationId}`);
  };

  return (
    <>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={openExportRebate}
        onClose={handleExportRebateClose}
        className="modal-overlay"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-description"
            variant="h5"
            className="center regular-bold"
            sx={{ mb: 5 }}
          >
            Are you sure you want to Export?
          </Typography>
          <Grid container spacing={5}>
            <Grid item md={6}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleExportRebateClose}
                className="w-100"
              >
                No
              </Button>
            </Grid>
            <Grid item md={6}>
              <Button
                variant="contained"
                color="primary"
                sx={{ color: "var(--white)" }}
                onClick={handleExportRebateDetailsDownload}
                className="w-100"
              >
                Yes
              </Button>
            </Grid>
          </Grid>
        </Box>
      </StyledModal>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="medium">
          <TableHead>
            <TableRow>
              <TableCell>Oder Id</TableCell>
              <TableCell>Pharmacy Name</TableCell>
              <TableCell>Order Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {MedicineOrders?.length > 0 ? (
              MedicineOrders?.map(
                (
                  {
                    order_id,
                    manufacturer_user_name,
                    manufacturer_company,
                    OrderStatus,
                    CreatedAt,
                  }: OrderData,
                  index: any
                ) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      color={
                        OrderStatus === true ? "primary.main" : "text.primary"
                      }
                    >
                      <TableCell>{order_id ? order_id : "N/A"}</TableCell>
                      <TableCell>
                        {manufacturer_user_name
                          ? manufacturer_user_name
                          : "N/A"}
                      </TableCell>

                      <TableCell>{CreatedAt ? moment(CreatedAt).format("DD/MM/YYYY") : "N/A"}</TableCell>

                      <TableCell>{getStatus(OrderStatus)}</TableCell>
                      <TableCell>
                        {
                          <div
                            style={{
                              textDecorationColor: "blue",
                              textDecorationLine: "underline",
                            }}
                            onClick={() => viewApplicationDetails(order_id)}
                          >
                            [Manage Order]
                          </div>
                        }
                      </TableCell>
                    </TableRow>
                  );
                }
              )
            ) : (
              <TableRow
                key={1}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {" "}
                <TableCell colSpan={7} className="no-data-found-cell">
                  {"No Record Found"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={TotalRecords}
            rowsPerPage={filterValue.Limit}
            page={filterValue.Page - 1}
            onPageChange={(e, value): any => handleChangePage(value)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={<span>Rows:</span>}
            sx={{
              ".MuiTablePagination-selectLabel, .MuiTablePagination-input": {
                fontWeight: "bold",
                color: "primary",
              },
            }}
          />
        </TableRow>
      </TableFooter>
      <InsuranceMedicineDataDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        insuranceMedicineData={insuranceMedicineData}
        insuranceIdsData={insuranceIdsData}
        insuranceNamesData={insuranceNamesData}
      />
    </>
  );
}

export default TableComponent;
