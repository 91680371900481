export const RoutesName = {
  LOGIN: "/",
  SIGNUP:"/signup",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password/:token",
  MANAGE_REBATE_LIST: "/manage-rebate",
  MEDICINE_LIST: "/medicine",
  MY_PROFILE: "/my-profile",
  CHANGE_PASSWORD: "/change-password",
  REQUEST_FORMULARY: "/request-formulary",
  REQUEST_FORMULARY_APPLICATIONS: "/request-formulary-applications",
  FORMULARY_APPLICATIONS_DETAILS: "/formulary-applications-details",
  MEDICINE_ORDERS:"/medicine-orders",
  MEDICINE_ORDERS_DETAILS:"/medicine-orders-details"
};
