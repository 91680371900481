import { Provider } from "react-redux";
import GlobalLoader from "./components/global-loader/golbal.loader";
import ToasterContainer from "./components/toast/toaster.container";
import RoutesManager from "./setup/routes-manager/routes.manager";
import store from "./setup/store/store";

function App() {
  return (
    <Provider store={store}>
      <RoutesManager />
      <ToasterContainer />
      <GlobalLoader />
    </Provider>
  );
}

export default App;
