import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Form, Formik } from "formik";
import FORM_FIELD from "../../validations/form-field";
import SCHEMA from "../../validations/schema";
import { getUserEmail } from "../../common/utils";
import {
  globalLoaderFalse,
  globalLoaderTrue,
} from "../../redux/slice/global.loader.slice";
import { useDispatch } from "react-redux";
import { updatePasswordAction } from "./action";
import showAlert from "../../common/utils/alert";
import { useNavigate } from "react-router-dom";
import { RoutesName } from "../../common/constants";

function ChangePassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (values: any, action: any) => {
    const payload = {
      PrincipalIdentifierValue: getUserEmail(),
      OldPassword: values.oldPassword,
      NewPassword: values.newPassword,
    };
    dispatch(globalLoaderTrue());
    updatePasswordAction(payload)
      .then((res: any) => {
        action.setSubmitting(false);
        dispatch(globalLoaderFalse());
        if (res.data.IsSuccess === true) {
          showAlert(1, "Password has been updated successfully.");
          navigate(RoutesName.MANAGE_REBATE_LIST);
        } else {
          showAlert(2, "Something went wrong, please try again!");
        }
      })
      .catch((err) => {
        dispatch(globalLoaderFalse());
        action.setSubmitting(false);
      });
  };

  return (
    <div
      className="page-change-password bg-white"
      style={{ width: 450, maxWidth: "100%", margin: "auto" }}
    >
      <h2 className="semi-bold center mb-30">Change Password</h2>
      <Formik
        initialValues={FORM_FIELD.updatePassword}
        validationSchema={SCHEMA.updatePassword}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
        }) => {
          return (
            <Form className="w-100">
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    id="outlined-basic"
                    label="Old Password"
                    variant="outlined"
                    name="oldPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.oldPassword}
                    error={!!errors.oldPassword && touched.oldPassword}
                    helperText={
                      touched.oldPassword &&
                      !!errors.oldPassword &&
                      errors.oldPassword
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    id="outlined-basic"
                    label="New Password"
                    variant="outlined"
                    name="newPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.newPassword}
                    error={!!errors.newPassword && touched.newPassword}
                    helperText={
                      touched.newPassword &&
                      !!errors.newPassword &&
                      errors.newPassword
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    id="outlined-basic"
                    label="Confirm New Password"
                    variant="outlined"
                    name="confirmNewPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmNewPassword}
                    error={
                      !!errors.confirmNewPassword && touched.confirmNewPassword
                    }
                    helperText={
                      touched.confirmNewPassword &&
                      !!errors.confirmNewPassword &&
                      errors.confirmNewPassword
                    }
                  />
                </Grid>
              </Grid>
              <div className="btn-cover mt-30">
                <button type="submit" className="btn btn-primary w-100">
                  {isSubmitting ? "Loading..." : "Update Password"}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ChangePassword;
