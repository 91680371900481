import { createSlice } from "@reduxjs/toolkit";
import { IGlobalLoader } from '../../components/global-loader/models';

const initialState = {
  isLoading: false
} as IGlobalLoader;

const globalLoaderSlice = createSlice({
  name: "gloabal-loader",
  initialState,
  reducers: {
    globalLoaderTrue(state) {
      state.isLoading = true;
    },
    globalLoaderFalse(state) {
      state.isLoading = false;
    }
  },
});

export const { globalLoaderTrue, globalLoaderFalse } = globalLoaderSlice.actions
export default globalLoaderSlice.reducer;