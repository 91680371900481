import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RoutesName } from "../../common/constants/RoutesName";
import ChangePassword from "../../pages/change-password";
import ForgotPassword from "../../pages/forgot-password";
import Login from "../../pages/login";
import ManageRebate from "../../pages/manage-rebate";
import Medicine from "../../pages/medicine";
import PageNotFound from "../../pages/page-not-found";
import ResetPassword from "../../pages/reset-password";
import PrivateRoute from "./private.route";
import MedicineOrders from "../../pages/medicine-orders";
import RequestFormulary from "../../pages/request-formulary-inclusion";
import Applications from "../../pages/formulary-applications";
import ApplicationsDetails from "../../pages/formulary-applications/application-details";
import OrderDetails from "../../pages/medicine-orders/order-details";
import Signup from "../../pages/signup";

function RoutesManager() {
  const {
    LOGIN,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    MANAGE_REBATE_LIST,
    MEDICINE_LIST,
    CHANGE_PASSWORD,
    REQUEST_FORMULARY,
    REQUEST_FORMULARY_APPLICATIONS,
    FORMULARY_APPLICATIONS_DETAILS,
    MEDICINE_ORDERS_DETAILS,
    MEDICINE_ORDERS,
    SIGNUP,
  } = RoutesName;

  return (
    <BrowserRouter>
      <Routes>
        <Route path={LOGIN} element={<Login />} />
        <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={RESET_PASSWORD} element={<ResetPassword />} />
        <Route path={SIGNUP} element={<Signup />} />
        <Route
          path={MANAGE_REBATE_LIST}
          element={
            <PrivateRoute>
              <ManageRebate />
            </PrivateRoute>
          }
        />
        <Route
          path={MEDICINE_LIST}
          element={
            <PrivateRoute>
              <Medicine />
            </PrivateRoute>
          }
        />
        <Route
          path={CHANGE_PASSWORD}
          element={
            <PrivateRoute>
              <ChangePassword />
            </PrivateRoute>
          }
        />

        <Route
          path={REQUEST_FORMULARY_APPLICATIONS}
          element={
            <PrivateRoute>
              {" "}
              <Applications />{" "}
            </PrivateRoute>
          }
        />

        <Route
          path={`${FORMULARY_APPLICATIONS_DETAILS}/:formId`}
          element={
            <PrivateRoute>
              {" "}
              <ApplicationsDetails />{" "}
            </PrivateRoute>
          }
        />

        <Route
          path={`${MEDICINE_ORDERS_DETAILS}/:formId`}
          element={
            <PrivateRoute>
              {" "}
              <OrderDetails />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path={MEDICINE_ORDERS}
          element={
            <PrivateRoute>
              {" "}
              <MedicineOrders />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path={REQUEST_FORMULARY}
          element={
            <PrivateRoute>
              {" "}
              <RequestFormulary />{" "}
            </PrivateRoute>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default RoutesManager;
