import {
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  Typography,
  MenuItem,
} from "@mui/material";
import { Button } from "@mui/material";
import { Formik, Form } from "formik";
import SCHEMA from "../../validations/schema";
import FORM_FIELD from "../../validations/form-field";
import { useEffect, useState } from "react";
import { getInsuranceCompanyList } from "./action";
import { Env } from "../../common/constants";

function FilterComponent({ applyFilter }: any) {
  const [insuranceCompany, setInsuranceCompany] = useState<any>([]);

  useEffect(() => {
    getInsuranceCompanyList().then((res: any) => {
      setInsuranceCompany(res?.data?.Data?.InsuranceCompanies);
    });
  }, []);

  const handleClear = () => {
    applyFilter(FORM_FIELD.medicineFilter);
  };

  const handleFilter = (values: any) => {
    if (
      values.fromDate !== "" ||
      values.toDate !== "" ||
      values.insuranceCompanyId
    )
      applyFilter(values);
  };
  return (
    <Formik
      initialValues={FORM_FIELD.medicineFilter}
      validationSchema={SCHEMA.medicineFilter}
      onSubmit={handleFilter}
      enableReinitialize
    >
      {({ handleChange, handleBlur, values, errors }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={2.6}>
              <Typography>From Date</Typography>
              <FormControl fullWidth>
                <TextField
                  name="fromDate"
                  type="date"
                  size="small"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.fromDate}
                  error={!!errors.fromDate}
                  helperText={!!errors.fromDate && errors.fromDate}
                  inputProps={{ max: values.toDate }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2.6}>
              <Typography>To Date</Typography>
              <FormControl fullWidth>
                <TextField
                  name="toDate"
                  type="date"
                  size="small"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.toDate}
                  error={!!errors.toDate}
                  helperText={!!errors.toDate && errors.toDate}
                  inputProps={{ min: values.fromDate }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2.6}>
              <FormControl
                fullWidth
                sx={{ marginTop: { xs: "10px", md: "24px" } }}
                className="insurance-dropdown"
              >
                <InputLabel id="insurance-company-id-select-label">
                  Select Insurance
                </InputLabel>
                <Select
                  name="insuranceCompanyId"
                  labelId="insurance-company-id-select-label"
                  id="insurance-company-id"
                  value={values.insuranceCompanyId}
                  label="Select Insurance"
                  size="small"
                  onChange={handleChange}
                >
                  {insuranceCompany?.length > 0
                    ? insuranceCompany?.map((item: any) => (
                        <MenuItem value={item.InsuranceCompanyId} className="justify-startt">
                          <img src={Env.REACT_APP_ASSET_PATH+item.LogoURL} width="25px" height="20px" className="mr-10" alt="" /> 
                          <h4>{item.Name}</h4>
                        </MenuItem>
                      ))
                    : ""}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={12} lg={4}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="medium"
                sx={{
                  fontWeight: "bold",
                  minWidth: "auto",
                  marginTop: { md: "0px", lg: "26px" },
                  marginRight: "16px",
                }}
              >
                Apply
              </Button>
              <Button
                variant="outlined"
                color="primary"
                type="reset"
                size="medium"
                sx={{
                  fontWeight: "bold",
                  minWidth: "auto",
                  marginTop: { md: "0px", lg: "26px" },
                }}
                onClick={handleClear}
                disabled={
                  values.toDate === "" &&
                  values.fromDate === "" &&
                  values.insuranceCompanyId === ""
                }
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default FilterComponent;
