import Grid from "@mui/material/Grid";
import logoImg from "../../assets/images/logo.svg";
import loginBannerImg from "../../assets/images/auth-banner.png";
import { Form, Formik } from "formik";
import FORM_FIELD from "../../validations/form-field";
import SCHEMA from "../../validations/schema";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ResponseCode, RoutesName } from "../../common/constants";
import { useDispatch } from "react-redux";
import {
  globalLoaderFalse,
  globalLoaderTrue,
} from "../../redux/slice/global.loader.slice";
import showAlert from "../../common/utils/alert";
import { resetPasswordAction, verifyResetPasswordLinkAction } from "./action";
import { useEffect, useState } from "react";
import { getAccessToken } from "../../common/utils";
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function ResetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useParams();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (getAccessToken()) {
      navigate(RoutesName.MANAGE_REBATE_LIST);
    }

    const payload = {
      SecretCode: location.token,
    };

    dispatch(globalLoaderTrue());
    verifyResetPasswordLinkAction(payload)
      .then((res: any) => {
        dispatch(globalLoaderFalse());
        if (res?.response?.status === ResponseCode.NOT_ACCEPTABLE) {
          navigate(RoutesName.LOGIN);
        }
      })
      .catch((err) => {
        dispatch(globalLoaderFalse());
      });
  }, []);

  const handleSubmit = (values: any, action: any) => {
    const payload = {
      SecretCode: location.token,
      Password: values.newPassword,
    };

    dispatch(globalLoaderTrue());
    resetPasswordAction(payload)
      .then((res: any) => {
        showAlert(1, "Password has been updated successfully.");
        action.setSubmitting(false);
        dispatch(globalLoaderFalse());
        navigate(RoutesName.LOGIN);
      })
      .catch((err) => {
        dispatch(globalLoaderFalse());
        action.setSubmitting(false);
      });
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <div className="page-login">
      <div className="login-content">
        <div className="left-portion">
          <div className="banner-img-cover">
            <img alt="" src={loginBannerImg} />
          </div>
          <img alt="" className="logo-image" src={logoImg} />
        </div>
        <div className="right-portion">
          <img alt="" className="logo-image for-mobile" src={logoImg} />
          <h2 className="color-blue bold mb-20">Reset Password</h2>
          <Formik
            initialValues={FORM_FIELD.resetPassword}
            validationSchema={SCHEMA.resetPassword}
            onSubmit={handleSubmit}
          >
            {({
              isSubmitting,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
            }) => {
              return (
                <Form className="w-100">
                  <Grid container spacing={6}>
                    <Grid item xs={12}>
                      <InputLabel htmlFor="outlined-adornment-new-password">
                        New Password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-new-password"
                        placeholder="New Password"
                        type={showNewPassword ? "text" : "password"}
                        name="newPassword"
                        fullWidth={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.newPassword}
                        error={!!errors.newPassword && touched.newPassword}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle newPassword visibility"
                              onClick={handleClickShowNewPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showNewPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {touched.newPassword &&
                        !!errors.newPassword &&
                        errors.newPassword && (
                          <FormHelperText
                            error
                            id="outlined-adornment-new-password-error"
                          >
                            {errors.newPassword}
                          </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel htmlFor="outlined-adornment-confirm-new-password">
                      Confirm New Password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-confirm-new-password"
                        placeholder="Confirm New Password"
                        type={showConfirmPassword ? "text" : "password"}
                        fullWidth={true}
                        name="confirmNewPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmNewPassword}
                        error={
                          !!errors.confirmNewPassword &&
                          touched.confirmNewPassword
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle confirmNewPassword visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {touched.confirmNewPassword &&
                        !!errors.confirmNewPassword &&
                        errors.confirmNewPassword && (
                          <FormHelperText
                            error
                            id="outlined-adornment-confirm-new-password-error"
                          >
                            {errors.confirmNewPassword}
                          </FormHelperText>
                        )}
                    </Grid>
                  </Grid>
                  <div className="right mt-10">
                    <Link to={RoutesName.LOGIN}>Back to login?</Link>
                  </div>
                  <div className="btn-cover mt-30">
                    <button type="submit" className="btn btn-primary">
                      {isSubmitting ? "Loading..." : "Update Password"}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
