import {
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Button,
  Typography,
  Box,
} from "@mui/material";
import logoImg from "../../assets/images/logo.svg";
import loginBannerImg from "../../assets/images/auth-banner.png";
import "./signup.css";
import { Form, Formik } from "formik";
import React, { useRef } from "react";
import FORM_FIELD from "../../validations/form-field";
import SCHEMA from "../../validations/schema";
import { RoutesName } from "../../common/constants";
import {
  decodeJwtToken,
  getAccessToken,
  setAccessToken,
  setRefreshToken,
  setUserEmail,
  setUserName,
  setUserID,
} from "../../common/utils";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import showAlert from "../../common/utils/alert";
import { useDispatch } from "react-redux";
import {
  globalLoaderFalse,
  globalLoaderTrue,
} from "../../redux/slice/global.loader.slice";
import { signupAction, getManufacturerAction, getCitiesAction } from "./action";
import { Role } from "../../types/global.types";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/system";

const StyledModal: any = styled(Modal)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const style = {
  width: 650,
  bgcolor: "background.paper",
  boxShadow: "0px 1px 3px 2px rgba(0,0,0,0.2)",
  borderRadius: "10px",
  p: 5,
};

const contryPhoneCode = [
  { key: "21", value: "21", text: "Kenya (+254)" },
  { key: "116", value: "116", text: "Belarus (+375)" },
  { key: "236", value: "236", text: "United State (+1)" },
];

const namePrefixes = [
  { key: "Dr.", value: "Dr.", text: "Dr." },
  { key: "Mr.", value: "Mr.", text: "Mr." },
  { key: "Mrs.", value: "Mrs.", text: "Mrs." },
  { key: "Ms.", value: "Ms.", text: "Ms." },
  { key: "Prof.", value: "Prof.", text: "Prof." },
];

const phoneCode = [
  { key: "254", value: "254", text: "Kenya (+254)" },
  { key: "375", value: "375", text: "Belarus (+375)" },
  { key: "1", value: "1", text: "United State (+1)" },
];

const countryList = [
  { key: "by", value: "21", text: "Belarus" },
  { key: "ke", value: "116", text: "Kenya" },
  { key: "us", value: "236", text: "US" },
];

function Signup() {
  const formikRef = useRef<any>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [manufactureList, setManufactureList]: any = useState([]);
  const [citiList, setCitiList]: any = useState([]);
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [manufactureCompanyName, setManufactureCompanyName] = useState("");
  const [clickedAdd, setClickedAdd] = useState(false);

  useEffect(() => {
    if (getAccessToken()) {
      navigate(RoutesName.MANAGE_REBATE_LIST);
    }
    getManufacture();
    handleCountryChange("116");
  }, []);

  console.log("manufactureCompanyName", manufactureCompanyName);
  const getManufacture = () => {
    getManufacturerAction()
      .then((result) => {
        let finalList: any = [];
        if (result.data?.Data.length > 0) {
          result.data?.Data.map((res: any) => {
            let newElem = {
              key: res.ManufacturerId,
              value: res.ManufacturerId,
              text: res.Name,
            };
            finalList.push(newElem);
          });
          setManufactureList(finalList);
        }
      })
      .catch((err) => {});
  };

  const handleCountryChange = (id: any) => {
    getCities(id);
    contryPhoneCode.map((item: any) => {
      if (item.key == id) {
        console.log("item", item);
      }
    });
  };

  const getCities = (id: any) => {
    getCitiesAction(id)
      .then((result) => {
        let finalList: any = [];
        if (result.data?.Data.length > 0) {
          result.data?.Data.map((res: any) => {
            let newElem = {
              key: res.c_id,
              value: res.c_id,
              text: res.c_name,
            };
            finalList.push(newElem);
          });
          setCitiList(finalList);
        }
      })
      .catch((err) => {});
  };

  const handleSubmit = (values: any, action: any) => {
    if (validatePhone(values.phone_number) == false) {
      action.setSubmitting(false);
      showAlert(2, "Please enter valid phone number.");
      return;
    }

    const payload = {
      country_code: values.country,
      phone_number: values.phone_number,
      phone_code: values.phonecode,
      name_prefix: values.title,
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      password: values.password,
      manufacturer: values.manufactureCompany,
      country: values.country,
      city: values.city,
      is_new_added_manufacturer: manufactureCompanyName
        ? manufactureCompanyName
        : "",
    };

    dispatch(globalLoaderTrue());
    signupAction(payload)
      .then((res: any) => {
        if (res.data.code == 200) {
          action.setSubmitting(false);
          dispatch(globalLoaderFalse());
          showAlert(
            1,
            "Your account has been setup successfully. An activation email has been sent to your email ID. Please check your email and activate your account."
          );
          navigate(RoutesName.LOGIN);
        } else {
          action.setSubmitting(false);
          dispatch(globalLoaderFalse());
          showAlert(2, res.data.message);
        }
      })
      .catch((err) => {
        dispatch(globalLoaderFalse());
        action.setSubmitting(false);
      });
  };

  const validatePhone = (phone_no: any) => {
    let phoneLength = phone_no.toString().length;

    if (phoneLength == 10) {
      let validPhone = phone_no.toString().charAt(0);
      let validPhoneAt1 = phone_no.toString().charAt(1);
      if (validPhone != "0" || validPhoneAt1 != "7") {
        return false;
      } else {
        phone_no = phone_no.toString().substring(1);
      }
    } else if (phoneLength == 9) {
      let validPhoneAt1 = phone_no.toString().charAt(0);
      if (validPhoneAt1 != "7") {
        return false;
      }
    } else {
      return false;
    }
    return true;
  };

  const handleOpenModel = (success=false) => {
    if(!success){
      setManufactureCompanyName("");
    }    
    if (!isOpenModel) {
      setClickedAdd(false);
    }
    setIsOpenModel(!isOpenModel);
  };

  const handleCompanyChange = (e: any) => {
    setManufactureCompanyName(e.target.value);
  };

  const addManufactureCompanyName = () => {
    setClickedAdd(true);
    if (!manufactureCompanyName) {
      return false;
    } else {
      const payload = {
        Name: manufactureCompanyName,
        manufactureCompanyName: manufactureCompanyName,
        add_manufacturer: "add_manufacturer",
        IsActive: 0,
      };
      dispatch(globalLoaderTrue());
      signupAction(payload)
        .then((res: any) => {
          if (res.data.code == 200) {
            handleChangeExternally(
              "manufactureCompany",
              res.data.manufacturer_id
            );
            dispatch(globalLoaderFalse());
            getManufacture();
            showAlert(1, "Manufacturer Added Successfully.");
            handleOpenModel(true);
            //navigate(RoutesName.LOGIN);
          } else {
            console.log("manufactureCompanyName 1", manufactureCompanyName);
            setManufactureCompanyName("");
            dispatch(globalLoaderFalse());
            showAlert(2, res.data.message);
          }
        })
        .catch((err) => {
          console.log("manufactureCompanyName 2", manufactureCompanyName);
          setManufactureCompanyName("");
          dispatch(globalLoaderFalse());
        });
    }
  };

  const handleChangeExternally = (fieldName: any, newValue: any) => {
    if (formikRef.current) {
      formikRef.current.setFieldValue(fieldName, newValue);
    }
  };

  return (
    <div className="page-signup">
      <div className="login-content">
        <div className="left-portion">
          <div className="banner-img-cover">
            <img alt="" src={loginBannerImg} />
          </div>
          <img alt="" className="logo-image" src={logoImg} />
        </div>
        <div className="right-portion">
          <img alt="" className="logo-image for-mobile" src={logoImg} />
          <h2 className="color-blue bold mb-20">Welcome to Livia</h2>
          <Formik
            initialValues={FORM_FIELD.signup}
            validationSchema={SCHEMA.signup}
            onSubmit={handleSubmit}
            innerRef={formikRef}
          >
            {({
              isSubmitting,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
            }) => {
              return (
                <Form className="w-100">
                  <Grid item xs={12} sm={12} md={7}>
                    <div className="justify-end">
                      <StyledModal
                        aria-labelledby="unstyled-modal-title"
                        aria-describedby="unstyled-modal-description"
                        open={isOpenModel}
                        onClose={()=>handleOpenModel()}
                        className="modal-overlay"
                      >
                        <Box sx={style}>
                          <Typography
                            id="modal-modal-description"
                            variant="h5"
                            className="center regular-bold"
                            sx={{ mb: 15 }}
                          >
                            Add Manufacturer company name
                          </Typography>
                          <Grid
                            container
                            spacing={1}
                            style={{ marginTop: "-115px", padding: "50px 0px" }}
                          >
                            <Grid item xs={10} sm={8} md={10} lg={12}>
                              <TextField
                                size="small"
                                id="outlined-basic"
                                label="Enter Manufacturer Company Name"
                                value={manufactureCompanyName}
                                onChange={handleCompanyChange}
                                variant="outlined"
                                name="manufactureCompanyName"
                              />
                              {!manufactureCompanyName && clickedAdd && (
                                <p style={{ color: "red" }}>
                                  Please enter Manufacturer Company Name
                                </p>
                              )}
                            </Grid>
                          </Grid>
                          <Grid container spacing={5}>
                            <Grid item md={6}>
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={()=>handleOpenModel()}
                                className="w-100"
                              >
                                Cancel
                              </Button>
                            </Grid>
                            <Grid item md={6}>
                              <Button
                                variant="contained"
                                color="primary"
                                sx={{ color: "var(--white)" }}
                                onClick={addManufactureCompanyName}
                                className="w-100"
                              >
                                Add
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </StyledModal>
                    </div>
                  </Grid>
                  <Grid container spacing={6}>
                    <Grid item xs={12} sm={6} md={8} lg={6}>
                      <FormControl fullWidth className="insurance-dropdown">
                        <InputLabel id="insurance-company-id-select-label">
                          Country
                        </InputLabel>
                        <Select
                          name="country"
                          labelId="country-id-select-label"
                          id="country"
                          value={values.country}
                          label="Select Country"
                          size="small"
                          onChange={handleChange}
                          error={!!errors.country && touched.country}
                        >
                          {countryList.length > 0
                            ? countryList?.map((item: any) => (
                                <MenuItem
                                  value={item.key}
                                  className="justify-startt"
                                  onClick={(e) =>
                                    handleCountryChange(item.value)
                                  }
                                >
                                  {" "}
                                  <h4>{item.text}</h4>
                                </MenuItem>
                              ))
                            : ""}
                        </Select>
                        {touched.country && errors.country ? (
                          <FormHelperText
                            sx={{
                              color: "#bf3333",
                              marginLeft: "16px !important",
                            }}
                          >
                            {touched.country && errors.country}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8} lg={6}>
                      <FormControl fullWidth className="insurance-dropdown">
                        <InputLabel id="insurance-company-id-select-label">
                          City
                        </InputLabel>
                        <Select
                          name="city"
                          labelId="city-id-select-label"
                          id="city"
                          disabled={!values.country}
                          value={values.city}
                          label="Select city"
                          size="small"
                          onChange={handleChange}
                          error={!!errors.city && touched.city}
                        >
                          {citiList.length > 0
                            ? citiList?.map((item: any) => (
                                <MenuItem
                                  value={item.key}
                                  className="justify-startt"
                                >
                                  {" "}
                                  <h4>{item.text}</h4>
                                </MenuItem>
                              ))
                            : ""}
                        </Select>
                        {values.country && touched.country && errors.country ? (
                          <FormHelperText
                            sx={{
                              color: "#bf3333",
                              marginLeft: "16px !important",
                            }}
                          >
                            {touched.country && errors.country}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8} lg={6}>
                      <FormControl fullWidth className="insurance-dropdown">
                        <InputLabel id="insurance-company-id-select-label">
                          Phone Code
                        </InputLabel>
                        <Select
                          name="phonecode"
                          labelId="phone-code-id-select-label"
                          id="phonecode"
                          value={values.phonecode}
                          label="Select phone code"
                          size="small"
                          onChange={handleChange}
                          error={!!errors.phonecode && touched.phonecode}
                        >
                          {phoneCode.length > 0
                            ? phoneCode?.map((item: any) => (
                                <MenuItem
                                  value={item.key}
                                  className="justify-startt"
                                >
                                  {" "}
                                  <h4>{item.text}</h4>
                                </MenuItem>
                              ))
                            : ""}
                        </Select>
                        {touched.phonecode && errors.phonecode ? (
                          <FormHelperText
                            sx={{
                              color: "#bf3333",
                              marginLeft: "16px !important",
                            }}
                          >
                            {touched.phonecode && errors.phonecode}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8} lg={6}>
                      <TextField
                        size="small"
                        id="outlined-basic"
                        label="Phone Number"
                        variant="outlined"
                        type="number"
                        name="phone_number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone_number}
                        error={!!errors.phone_number && touched.phone_number}
                        helperText={
                          touched.phone_number &&
                          !!errors.phone_number &&
                          errors.phone_number
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={8} lg={3}>
                      <FormControl fullWidth className="insurance-dropdown">
                        <InputLabel id="insurance-company-id-select-label">
                          Select title
                        </InputLabel>
                        <Select
                          name="title"
                          labelId="title-id-select-label"
                          id="title"
                          value={values.title}
                          label="Select Title"
                          size="small"
                          onChange={handleChange}
                          error={!!errors.title && touched.title}
                        >
                          {namePrefixes.length > 0
                            ? namePrefixes?.map((item: any) => (
                                <MenuItem
                                  value={item.key}
                                  className="justify-startt"
                                >
                                  {" "}
                                  <h4>{item.text}</h4>
                                </MenuItem>
                              ))
                            : ""}
                        </Select>
                        {touched.title && errors.title ? (
                          <FormHelperText
                            sx={{
                              color: "#bf3333",
                              marginLeft: "16px !important",
                            }}
                          >
                            {touched.title && errors.title}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={8} lg={4.5}>
                      <TextField
                        id="outlined-basic"
                        label="First Name"
                        size="small"
                        variant="outlined"
                        name="firstName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        error={!!errors.firstName && touched.firstName}
                        helperText={
                          touched.firstName &&
                          !!errors.firstName &&
                          errors.firstName
                        }
                      />
                    </Grid>
                    <Grid item xs={10} sm={4} md={8} lg={4.5}>
                      <TextField
                        id="outlined-basic"
                        label="last Name"
                        size="small"
                        variant="outlined"
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        error={!!errors.lastName && touched.lastName}
                        helperText={
                          touched.lastName &&
                          !!errors.lastName &&
                          errors.lastName
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Email Address"
                        size="small"
                        variant="outlined"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        error={!!errors.email && touched.email}
                        helperText={
                          touched.email && !!errors.email && errors.email
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={8} lg={8}>
                      <FormControl fullWidth className="insurance-dropdown">
                        <InputLabel id="insurance-company-id-select-label">
                          Select Manufacturer
                        </InputLabel>
                        <Select
                          name="manufactureCompany"
                          labelId="title-id-select-label"
                          id="manufactureCompany"
                          value={values.manufactureCompany}
                          label="Select Manufacturer Company"
                          size="small"
                          onChange={handleChange}
                          error={
                            !!errors.manufactureCompany &&
                            touched.manufactureCompany
                          }
                        >
                          {manufactureList.length > 0
                            ? manufactureList?.map((item: any) => (
                                <MenuItem
                                  value={item.key}
                                  className="justify-startt"
                                >
                                  {" "}
                                  <h4>{item.text}</h4>
                                </MenuItem>
                              ))
                            : ""}
                        </Select>
                        {touched.manufactureCompany &&
                        errors.manufactureCompany ? (
                          <FormHelperText
                            sx={{
                              color: "#bf3333",
                              marginLeft: "16px !important",
                            }}
                          >
                            {touched.manufactureCompany &&
                              errors.manufactureCompany}
                          </FormHelperText>
                        ) : null}
                        <label>
                          Not in list?{" "}
                          <a
                            onClick={()=>handleOpenModel()}
                            style={{ color: "blue", cursor: "pointer" }}
                          >
                            Click Here
                          </a>{" "}
                          to add
                        </label>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={8} lg={4}>
                      <TextField
                        type="password"
                        size="small"
                        id="outlined-basic"
                        label="Create Password"
                        variant="outlined"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        error={!!errors.password && touched.password}
                        helperText={
                          touched.password &&
                          !!errors.password &&
                          errors.password
                        }
                      />
                    </Grid>
                  </Grid>
                  <div className="right mt-10">
                    <Link to={RoutesName.LOGIN}>Login?</Link>
                  </div>
                  <div className="btn-cover mt-30">
                    <button type="submit" className="btn btn-primary">
                      {isSubmitting ? "Loading..." : "Signup"}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Signup;
