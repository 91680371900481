import { useEffect, useState } from "react";
import { Paper, Button, Box, Typography, Grid } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InputBase from "@mui/material/InputBase";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles, createStyles } from "@mui/styles";
import ClearIcon from "@mui/icons-material/Clear";
import "../../assets/styles/table.css";
import "../../assets/styles/listing-filters.css";
import FilterComponent from "./filter";
import TableComponent from "./table";
import { IFilter } from "./models";
import {
  globalLoaderFalse,
  globalLoaderTrue,
} from "../../redux/slice/global.loader.slice";
import { getDrugsFormRequestList } from '../../redux/slice/drug.request.form.slice'
//@ts-ignore
import Modal from '@mui/material/Modal';
import { styled } from "@mui/system";
import { fetchCsvData, fetchRebateDetailsCsvData } from "./action";

const useStyles = makeStyles(() =>
  createStyles({
    csv: {
      color: "var(----light-green)",
    },
  })
);

const StyledModal:any = styled(Modal)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const style = {
  width: 440,
  bgcolor: "background.paper",
  boxShadow: "0px 1px 3px 2px rgba(0,0,0,0.2)",
  borderRadius: "10px",
  p: 5,
};

function Applications() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openExportRebate, setOpenExportRebate] = useState(false);
  const [filter, setFilter] = useState(false);
  const [filterValue, setFilterValue] = useState<IFilter>({
    Page: 1,
    Limit: 10,
    InsuranceCompanyId: "",
    FromDate: "",
    ToDate: "",
    Search: ""
  });

  useEffect(() => {
    dispatch(getDrugsFormRequestList(filterValue) as any);
  }, [filterValue, dispatch]);

  const handleFilterClick = () => {
    setFilter(!filter);
  };
  const toggleArrow = (filter: Boolean) => {
    return filter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;
  };

  const handleChangePage = (event: any) => {
    setFilterValue((prev: any) => ({
      ...prev,
      Page: event + 1,
    }));
  };

  const handleChangeRowsPerPage = (event: any) => {
    setFilterValue((prev: any) => ({
      ...prev,
      Page: 1,
      Limit: event.target.value,
    }));
  };

  const handleSearchChange = (event: any) => {
    setFilterValue((prev: any) => ({
      ...prev,
      Page: 1,
      Search: event.target.value,
    }));
  };

  const clearSearch = () => {
    setFilterValue((prev: any) => ({
      ...prev,
      Page: 1,
      Search: "",
    }));
  };

  const applyFilter = (values: any) => {
    setFilterValue((prev: any) => ({
      ...prev,
      Page: 1,
      FromDate: values.fromDate,
      ToDate: values.toDate,
      InsuranceCompanyId: values.insuranceCompanyId,
    }));
  };

  const handleExportDownload = () => {
    dispatch(globalLoaderTrue());
    const fileName = `Medicine-list-${new Date().toLocaleString("default", {
      month: "long",
    })}-${new Date().getDate()}.csv`;
    fetchCsvData(filterValue)
      .then((res: any) => {
        dispatch(globalLoaderFalse());
        if (res !== undefined) {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;

          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err: any) => {
        dispatch(globalLoaderFalse());
      });
    setOpen(false);
  };

  const handleExportRebateDetailsDownload = () => {
    dispatch(globalLoaderTrue());
    const fileName = `Rebate-details-${new Date().toLocaleString("default", {
      month: "long",
    })}-${new Date().getDate()}.csv`;
    fetchRebateDetailsCsvData(filterValue)
      .then((res: any) => {
        dispatch(globalLoaderFalse());
        if (res !== undefined) {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;

          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err: any) => {
        dispatch(globalLoaderFalse());
      });
    setOpenExportRebate(false);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(globalLoaderFalse());
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleExportRebateClose = () => {
    setOpenExportRebate(false);
    dispatch(globalLoaderFalse());
  };

  const handleExportRebateOpen = () => {
    setOpenExportRebate(true);
  };

  return (
    <div className="page-drug-formulary bg-white">
      <Grid container sx={{ mb: "20px" }} spacing={3}>
        <Grid item xs={12} sm={12} md={5}>
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "100%",
              boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.2)",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search by brand and API"
              value={filterValue.Search}
              onChange={handleSearchChange}
            />
            {filterValue.Search.length > 0 ? (
              <Button onClick={clearSearch}>
                <ClearIcon />
              </Button>
            ) : (
              ""
            )}
            <IconButton sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={7}>
          <div className="justify-end">
            <StyledModal
              aria-labelledby="unstyled-modal-title"
              aria-describedby="unstyled-modal-description"
              open={open}
              onClose={handleClose}
              className="modal-overlay"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-description"
                  variant="h5"
                  className="center regular-bold"
                  sx={{ mb: 5 }}
                >
                  Are you sure you want to Export?
                </Typography>
                <Grid container spacing={5}>
                  <Grid item md={6}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleClose}
                      className="w-100"
                    >
                      No
                    </Button>
                  </Grid>
                  <Grid item md={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ color: "var(--white)" }}
                      onClick={handleExportDownload}
                      className="w-100"
                    >
                      Yes
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </StyledModal>
            <StyledModal
              aria-labelledby="unstyled-modal-title"
              aria-describedby="unstyled-modal-description"
              open={openExportRebate}
              onClose={handleExportRebateClose}
              className="modal-overlay"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-description"
                  variant="h5"
                  className="center regular-bold"
                  sx={{ mb: 5 }}
                >
                  Are you sure you want to Export?
                </Typography>
                <Grid container spacing={5}>
                  <Grid item md={6}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleExportRebateClose}
                      className="w-100"
                    >
                      No
                    </Button>
                  </Grid>
                  <Grid item md={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ color: "var(--white)" }}
                      onClick={handleExportRebateDetailsDownload}
                      className="w-100"
                    >
                      Yes
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </StyledModal>
            {/* <div className="dropdown-cover mr-10">
              <Button
                onClick={handleFilterClick}
                variant="outlined"
                size="large"
                sx={{
                  maxWidth: "180px",
                  minWidth: "80px",
                  bgcolor: "var(--white)",
                }}
              >
                <FilterListIcon />
                &nbsp;
                <span>Filters</span>
                {toggleArrow(filter)}
              </Button>
            </div> */}
            {/* <Button
              onClick={handleOpen}
              variant="contained"
              size="large"
              className="mr-10"
              sx={{
                maxWidth: "180px",
                minWidth: "80px",
                color: "var(--white)",
              }}
            >
              <span>Export</span>
            </Button>
            <Button
              onClick={handleExportRebateOpen}
              variant="contained"
              size="large"
              sx={{
                color: "var(--white)",
              }}
            >
              <span>Export Rebate Details</span>
            </Button> */}
          </div>
        </Grid>
        {/* {filter ? (
          <Grid item md={12} style={{ width: "100%" }}>
            <FilterComponent applyFilter={applyFilter} />
          </Grid>
        ) : (
          " "
        )} */}
      </Grid>
      <div className="table-cover">
        <TableComponent
          filterValue={filterValue}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}

export default Applications;
