import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EndPoint } from "../../common/constants";
import showAlert from "../../common/utils/alert";
import { IFilter } from "../../pages/manage-rebate/models";
import axiosI from "../../setup/axios/axios.instance";
import { manageRebate } from "../redux-state";
import { globalLoaderFalse, globalLoaderTrue } from "./global.loader.slice";

// First, create the thunk
export const getManageRebateList = createAsyncThunk(
  "fetchManageRebate",
  async (filterValue: IFilter, { rejectWithValue, dispatch }) => {
    const { Page, Limit, FromDate, ToDate, Search } = filterValue;
    const queryString = `?Page=${Page}&Limit=${Limit}&FromDate=${FromDate}&ToDate=${ToDate}&Search=${Search}`;
    dispatch(globalLoaderTrue());
    try {
      const response = await axiosI.get(
        `${EndPoint.MANAGE_REBATE_LIST}${queryString}`
      );
      dispatch(globalLoaderFalse());
      return response.data?.Data;
    } catch (err: any) {
      dispatch(globalLoaderFalse());
      return rejectWithValue(err?.errors[0]);
    }
  }
);

const initialState = {
  entities: manageRebate,
  loading: "idle",
};

const manageRebateSlice = createSlice({
  name: "manage-rebate",
  initialState,
  reducers: {
    //
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getManageRebateList.fulfilled, (state, action) => {
      state.entities = action.payload;
    });
  },
});

export default manageRebateSlice.reducer;
