const manageRebate = {
  Page: 1,
  TotalRecords: 0,
  TotalRebateAmount: 0.0,
  TotalOutstandingAmount: 0.0,
  PaidRebateAmount: 0.0,
  Amounts: [],
};

export { manageRebate };
