import { useEffect, useState } from "react";
import {
  Theme,
  Box,
  Grid,
  TextField,
  FormControlLabel,
  InputAdornment,
  Typography,
} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch } from "react-redux";
import { makeStyles, createStyles } from "@mui/styles";
import "../../assets/styles/table.css";
import "../../assets/styles/listing-filters.css";
import previewImg from "./../../assets/images/auth-banner.png";
import FilterComponent from "./filter";
import FileBrowse from "../../components/file-browse/fileBrowse";
import { useParams } from "react-router-dom";
import { RoutesName, Env } from "../../common/constants";
import { useNavigate } from "react-router-dom";

import { IFilter } from "./models";
import {
  globalLoaderFalse,
  globalLoaderTrue,
} from "../../redux/slice/global.loader.slice";
import { getMedicineList } from "../../redux/slice/medicine.slice";
import Modal from "@mui/material/Modal";

import { styled } from "@mui/system";
import {
  addDrugRequestForm,
  getDrugRequestFormById,
  getInsuranceCompanyList,
} from "./action";

import { Formik, Form } from "formik";

import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FORM_FIELD from "../../validations/form-field";
import SCHEMA from "../../validations/schema";

import zipicon from "../../assets/zipicon.png";
import pdfIcon from "../../assets/pdficon.png";
import wordIcon from "../../assets/wordicon.png";
import docIcon from "../../assets/Excel-icon.png";

import Select, { SelectChangeEvent } from "@mui/material/Select";

import "./medicine.css";
import showAlert from "../../common/utils/alert";
import {
  deleteFromS3,
  prePareInitialValues,
  preparePayload,
  getInsuranceCompanyNamesByStatus,
  getInsuranceIds,
} from "../../common/utils";

const formStatus = [
  { key: "1", text: "Pending", value: "Pending" },
  { key: "2", text: "In Progress", value: "In Progress" },
  { key: "3", text: "Submitted", value: "Submitted" },
  { key: "4", text: "Approved", value: "Approved" },
  { key: "5", text: "Rejected", value: "Rejected" },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    csv: {
      color: "var(----light-green)",
    },
  })
);

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const style = {
  width: 440,
  bgcolor: "background.paper",
  boxShadow: "0px 1px 3px 2px rgba(0,0,0,0.2)",
  borderRadius: "10px",
  p: 5,
};

function ApplicationsDetails() {
  const { REQUEST_FORMULARY_APPLICATIONS } = RoutesName;
  const classes = useStyles();
  const { formId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openExportRebate, setOpenExportRebate] = useState(false);
  const [filter, setFilter] = useState(false);
  const [submitType, setSubmitType] = useState(2);
  const [isNotValid, setNotValid] = useState<any>("");
  const [businessNatureOther, setBusinessNatureOther] = useState<any>("");
  const [natureOfBusinessVal, setNatureOfBusiness] = useState<any>("");
  const [clientsVal, setClient] = useState<any>([]);
  const [clientOther, setClientOther] = useState<any>("");
  const [drugFormID, setFormID] = useState<any>("");
  const [DrugRequestFormFiles, setDrugRequestFormFiles] = useState<any>([]);
  const [formValues, setFormValues] = useState<any>([]);
  const [initialFormValues, setInitialValues] = useState(
    FORM_FIELD.applicationFormulary
  );
  const [viewOnly, setViewOnly] = useState(false);
  const [insuranceCompany, setInsuranceCompany] = useState<any>([]);
  const [insuranceCompanyIds, setInsuranceCompanyIds] = useState<any>([]);
  const [brandName, setBrandName] = useState<any>([]);
  const [Status, setStatus] = useState<any>([]);
  const [declinedReason, setDeclinedReason] = useState<any>([]);
  const [approvedInsuranceCompany, setApprovedInsuranceCompany] =
    useState<any>("");
  const [declinedInsuranceCompany, setDeclinedInsuranceCompany] =
    useState<any>("");
  console.log("DrugRequestFormName", brandName);

  const [filterValue, setFilterValue] = useState<IFilter>({
    Page: 1,
    Limit: 10,
    InsuranceCompanyId: "",
    FromDate: "",
    ToDate: "",
    Search: "",
  });

  useEffect(() => {
    getInsuranceCompanyList().then((res: any) => {
      setInsuranceCompany(res?.data?.Data?.InsuranceCompanies);
    });
  }, []);

  useEffect(() => {
    setFormID(formId);

    dispatch(globalLoaderTrue());
    getDrugRequestFormById(formId).then((res: any) => {
      if (res) {
        console.log("res", res?.Data?.DrugRequestFormName);
        setBrandName(res?.Data?.DrugRequestFormName);
        setStatus(res?.Data?.Status);
        setDeclinedReason(res?.Data?.DeclinedReason);
        if (
          res?.Data?.Status === 3 ||
          res?.Data?.Status === 4 ||
          res?.Data?.Status === 5
        ) {
          setApprovedInsuranceCompany(
            getInsuranceCompanyNamesByStatus(res?.Data?.InsuranceCompanies, 2)
          );
          setDeclinedInsuranceCompany(
            getInsuranceCompanyNamesByStatus(res?.Data?.InsuranceCompanies, 3)
          );
        }

        setViewOnly(
          res?.Data?.Status === 3 ||
            res?.Data?.Status === 4 ||
            res?.Data?.Status === 5
            ? true
            : false
        );
        const obj = prePareInitialValues(res, FORM_FIELD.applicationFormulary);
        obj.TradePricePerUnit = res?.Data?.TradePrice;
        obj.RebatePercentage = res?.Data?.RebatePercentage;
        obj.brand_name = res?.Data?.brand_name;
        obj.name = res?.Data?.name;
        if (obj.natureOfBusinessOther) {
          setBusinessNatureOther(1);
        }
        if (obj.otherClient) setClientOther(obj.otherClient);

        const formularyFiles =
          res?.Data?.DrugRequestFormData?.DrugRequestFormFiles || [];

        setInitialValues(obj);
        setDrugRequestFormFiles(formularyFiles);
        setNatureOfBusiness(res?.Data?.DrugRequestFormData?.NatureOfBusiness);

        if (
          res?.Data?.InsuranceCompanies &&
          res?.Data?.InsuranceCompanies?.length > 0 &&
          res?.Data?.InsuranceCompanies?.length != undefined
        ) {
          //alert('sdfsd')
          setInsuranceCompanyIds(
            getInsuranceIds(res?.Data?.InsuranceCompanies)
          );
        }
        setClient(
          res?.Data?.DrugRequestFormData?.Clients
            ? res?.Data?.DrugRequestFormData?.Clients
            : []
        );
        dispatch(globalLoaderFalse());
      }
    });
  }, [formId]);
  useEffect(() => {
    dispatch(getMedicineList(filterValue) as any);
  }, [filterValue, dispatch]);

  const getUploadedPath = (FileUrl: any, FileName: any) => {
    setDrugRequestFormFiles((DrugRequestFormFiles: any) => [
      ...DrugRequestFormFiles,
      { FileName, FileUrl },
    ]);
  };

  const deleteDrugRequestFormFiles = (valueName: any, FileName: any) => {
    if (!viewOnly) {
      dispatch(globalLoaderTrue());
      deleteFromS3(valueName).then((res: any) => {
        if (res) {
          const newDrugRequestFormFiles = DrugRequestFormFiles.filter(
            (item: any) => item.FileName !== FileName
          );
          setDrugRequestFormFiles(newDrugRequestFormFiles);
          dispatch(globalLoaderFalse());
        }
      });
    }
  };

  const insuranceCompanyChange = (event: any) => {
    const val = parseInt(event.target.value);
    if (insuranceCompanyIds?.includes(val)) {
      const newInsuranceCompanyIds = insuranceCompanyIds?.filter(
        (item: any) => item !== val
      );
      setInsuranceCompanyIds(newInsuranceCompanyIds);
    } else {
      setInsuranceCompanyIds([...insuranceCompanyIds, val]);
    }
  };

  const handleSearchChange = (event: any) => {
    setFilterValue((prev: any) => ({
      ...prev,
      Page: 1,
      Search: event.target.value,
    }));
  };

  const applyFilter = (values: any) => {
    setFilterValue((prev: any) => ({
      ...prev,
      Page: 1,
      FromDate: values.fromDate,
      ToDate: values.toDate,
      InsuranceCompanyId: values.insuranceCompanyId,
    }));
  };

  const [state, setState] = useState({
    gilad: true,
    jason: false,
    antoine: false,
  });

  const setSubmitTypeVal = (val: any) => {
    setSubmitType(val);
  };

  const handleSubmit = (values: any, action: any) => {
    values.Clients = clientsVal;

    const natureOfBusinessOptions = [
      "Hospitals",
      "Pharmaceutical Distributors",
      "Pharmaceutical Retailers",
      "Institutions",
    ];
    const clientsValnNew = values?.Clients?.filter((item: any) => {
      if (natureOfBusinessOptions.includes(item)) {
        return item;
      }
    });

    const otherBusiness = values.natureOfBusinessOther
      ? values.natureOfBusinessOther
      : "";

    if (natureOfBusinessVal == "other" && otherBusiness == "") {
      setNotValid(1);
      showAlert(2, "Please enter nature of business");
      return;
    }

    if (clientOther && !values.otherClient) {
      setNotValid(1);
      showAlert(2, "Please enter other client");
      return;
    }

    if (submitType == 3 && values.TradePricePerUnit == 0) {
      setNotValid(1);
      showAlert(2, "Please enter trade price per unit");
      return;
    }

    if (submitType == 3 && insuranceCompanyIds.length == 0) {
      setNotValid(1);
      showAlert(2, "Please select insurance company");
      return;
    }
    values.NumberOfProductsRegisteredWithThePPBToDate = parseInt(
      values.NumberOfProductsRegisteredWithThePPBToDate
    );

    if (
      values.ProductsToBeIncludedInTheHospitalFormulary &&
      values.ProductsToBeIncludedInTheHospitalFormulary != undefined
    ) {
      values.ProductsToBeIncludedInTheHospitalFormulary = [
        values.ProductsToBeIncludedInTheHospitalFormulary,
      ];
    }
    if (values.otherClient) setClient(values.clientOther);

    if (clientOther) {
      clientsValnNew.push(values.otherClient);
    }
    if (insuranceCompanyIds) {
      values.InsuranceCompanies = insuranceCompanyIds;
    }

    preparePayload(values);
    delete values.name;
    delete values.brand_name;
    const payload = {
      ...values,
      DrugRequestFormId: parseInt(drugFormID),
      Status: submitType,
      NatureOfBusiness:
        natureOfBusinessVal && natureOfBusinessVal == "other"
          ? otherBusiness
          : natureOfBusinessVal,
      DrugRequestFormFiles,
      CountryId: parseInt(values.CountryId),
      Clients: clientsValnNew,
    };
    // console.log('payload ', payload)
    // return

    dispatch(globalLoaderTrue());
    addDrugRequestForm(payload)
      .then((res: any) => {
        if (res?.status === 200) {
          dispatch(globalLoaderFalse());
          setNotValid("");
          showAlert(1, "Form submitted successfully");
          navigate(`${REQUEST_FORMULARY_APPLICATIONS}`);
        } else {
          dispatch(globalLoaderFalse());
          setNotValid("Something went wrong");
        }
      })
      .catch((err: any) => {});
  };

  const handleChangeNature = (event: SelectChangeEvent) => {
    setNatureOfBusiness(event.target.value);
    setBusinessNatureOther("");
    if (event.target.value === "other") {
      if (businessNatureOther) setNatureOfBusiness("");
      setBusinessNatureOther(!businessNatureOther);
    }
  };

  const handleClient = (event: SelectChangeEvent) => {
    const isExist = clientsVal?.filter((val: any) => val == event.target.value);
    if (isExist?.length > 0) {
      const newArr = clientsVal?.filter(
        (val: any) => val != event.target.value
      );
      setClient(newArr);
    } else {
      setClient([...clientsVal, event.target.value]);
    }
    if (event.target.value === "other") {
      setClientOther(!clientOther);
    }
  };

  const uploadedFiles = (fileName: any) => {
    const isUploaded = DrugRequestFormFiles?.filter(
      (file: any) => file.FileName === fileName
    );
    if (isUploaded?.length > 0) {
      const renderFile = showIcon(isUploaded[0].FileUrl);
      //isUploaded[0].FileUrl
      return renderFile;
    } else {
      return false;
    }
  };

  const openInNewTab = (fileName: any) => {
    const isUploaded = DrugRequestFormFiles?.filter(
      (file: any) => file.FileName === fileName
    );
    const newWindow = window.open(
      isUploaded[0].FileUrl,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const showIcon = (image: any) => {
    let uploadType = image?.split("_fromu.");
    let imgType = uploadType[1];
    
    let output = image;
    switch (imgType) {
      case "jpg":
      case "jpeg":
      case "png":
        output = image;
        break;
      case "pdf":
        output = pdfIcon;
        break;
      case "zip":
        output = zipicon;
        break;
      case "docx":
      case "doc":
      case "msword":
      case "vnd.openxmlformats-officedocument.wordprocessingml.document":
        output = wordIcon;
        break;
      case "xls":
      case "xlsx":
      case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        output = docIcon;
        break;
      default:
        return image;
    }
    return output;
  };

  const { gilad, jason, antoine } = state;
  const error = [gilad, jason, antoine].filter((v) => v).length !== 2;

  return (
    <div className="page-drug-formulary bg-white">
      <Grid container sx={{ mb: "20px" }} spacing={3}>
        {filter ? (
          <Grid item md={12} style={{ width: "100%" }}>
            <FilterComponent applyFilter={applyFilter} />
          </Grid>
        ) : (
          " "
        )}
      </Grid>
      <div>
        <Formik
          initialValues={initialFormValues}
          validationSchema={SCHEMA.applicationFormulary}
          onSubmit={handleSubmit}
        >
          {({
            isSubmitting,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
          }) => {
            return (
              <Form className="w-100">
                {viewOnly ? (
                  <Box style={{ marginLeft: "0px" }} m={3} pt={3}>
                    <h3>
                      Application Status:{" "}
                      <label style={{ color: Status == 5 ? "red" : "green" }}>
                        {formStatus[Status - 1].text}
                      </label>{" "}
                    </h3>
                    {Status == 5 ? (
                      <h3>
                        Declined Reason:{" "}
                        <label style={{ color: "red" }}>{declinedReason}</label>{" "}
                      </h3>
                    ) : (
                      ""
                    )}
                    {approvedInsuranceCompany ? (
                      <h3>
                        Approved Insurance:{" "}
                        <label style={{ color: "green" }}>
                          {approvedInsuranceCompany}
                        </label>{" "}
                      </h3>
                    ) : (
                      ""
                    )}
                    {declinedInsuranceCompany ? (
                      <h3>
                        Declined Insurance:{" "}
                        <label style={{ color: "red" }}>
                          {declinedInsuranceCompany}
                        </label>{" "}
                      </h3>
                    ) : (
                      ""
                    )}
                  </Box>
                ) : (
                  ""
                )}

                <Grid container spacing={4}>
                  <Grid item xs={12} lg={6} xl={4}>
                    <TextField
                      type="text"
                      id="outlined-basic"
                      label="Name of Company *"
                      variant="outlined"
                      name="NameOfCompany"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={true}
                      value={values.NameOfCompany}
                      error={!!errors.NameOfCompany && touched.NameOfCompany}
                      helperText={
                        touched.NameOfCompany &&
                        !!errors.NameOfCompany &&
                        errors.NameOfCompany
                      }
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} xl={4}>
                    <TextField
                      type="text"
                      disabled={viewOnly}
                      id="outlined-basic"
                      label="KRA PIN Number *"
                      variant="outlined"
                      name="BusinessRegistrationNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.BusinessRegistrationNumber}
                      error={
                        !!errors.BusinessRegistrationNumber &&
                        touched.BusinessRegistrationNumber
                      }
                      helperText={
                        touched.BusinessRegistrationNumber &&
                        !!errors.BusinessRegistrationNumber &&
                        errors.BusinessRegistrationNumber
                      }
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} xl={4}>
                    <TextField
                      type="text"
                      disabled={viewOnly}
                      id="outlined-basic"
                      label="PPB Premise Registration Number *"
                      variant="outlined"
                      name="PPBPremiseRegistrationNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.PPBPremiseRegistrationNumber}
                      error={
                        !!errors.PPBPremiseRegistrationNumber &&
                        touched.PPBPremiseRegistrationNumber
                      }
                      helperText={
                        touched.PPBPremiseRegistrationNumber &&
                        !!errors.PPBPremiseRegistrationNumber &&
                        errors.PPBPremiseRegistrationNumber
                      }
                    />
                  </Grid>

                  <Grid item xs={12} lg={6} xl={4}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Country *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="CountryId"
                          value={values.CountryId}
                          label="Country"
                          onChange={handleChange}
                          disabled={viewOnly}
                          error={!!errors.CountryId && touched.CountryId}
                          // helperText={
                          //   touched.YearEstablished &&
                          //   !!errors.YearEstablished &&
                          //   errors.YearEstablished
                          // }
                        >
                          <MenuItem value={116}>Kenya</MenuItem>
                          <MenuItem value={236}>US</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={6} xl={4}>
                    <TextField
                      type="text"
                      id="outlined-basic"
                      label="County"
                      variant="outlined"
                      name="County"
                      disabled={viewOnly}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.County}
                      error={!!errors.County && touched.County}
                      helperText={
                        touched.County && !!errors.County && errors.County
                      }
                    />
                  </Grid>

                  <Grid item xs={12} lg={6} xl={4}>
                    <TextField
                      type="text"
                      disabled={viewOnly}
                      id="outlined-basic"
                      label="Postal Address"
                      variant="outlined"
                      name="PostalAddress"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.PostalAddress}
                      error={!!errors.PostalAddress && touched.PostalAddress}
                      helperText={
                        touched.PostalAddress &&
                        !!errors.PostalAddress &&
                        errors.PostalAddress
                      }
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} xl={4}>
                    <TextField
                      type="text"
                      disabled={viewOnly}
                      id="outlined-basic"
                      label="Official email *"
                      variant="outlined"
                      name="OfficialEmail"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.OfficialEmail}
                      error={!!errors.OfficialEmail && touched.OfficialEmail}
                      helperText={
                        touched.OfficialEmail &&
                        !!errors.OfficialEmail &&
                        errors.OfficialEmail
                      }
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} xl={4}>
                    <TextField
                      type="text"
                      disabled={viewOnly}
                      id="outlined-basic"
                      label="TelephoneNumber *"
                      variant="outlined"
                      name="TelephoneNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.TelephoneNumber}
                      error={
                        !!errors.TelephoneNumber && touched.TelephoneNumber
                      }
                      helperText={
                        touched.TelephoneNumber &&
                        !!errors.TelephoneNumber &&
                        errors.TelephoneNumber
                      }
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} xl={4}>
                    <TextField
                      type="text"
                      disabled={viewOnly}
                      id="outlined-basic"
                      label="Alternative contact *"
                      variant="outlined"
                      name="AlternativeContact"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.AlternativeContact}
                      error={
                        !!errors.AlternativeContact &&
                        touched.AlternativeContact
                      }
                      helperText={
                        touched.AlternativeContact &&
                        !!errors.AlternativeContact &&
                        errors.AlternativeContact
                      }
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} xl={4}>
                    <TextField
                      type="text"
                      disabled={viewOnly}
                      id="outlined-basic"
                      label="Company website"
                      variant="outlined"
                      name="CompanyWebsite"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.CompanyWebsite}
                      error={!!errors.CompanyWebsite && touched.CompanyWebsite}
                      helperText={
                        touched.CompanyWebsite &&
                        !!errors.CompanyWebsite &&
                        errors.CompanyWebsite
                      }
                    />
                  </Grid>
                  <Grid item sm={12} lg={6} xl={4}>
                    <TextField
                      type="text"
                      disabled={viewOnly}
                      id="outlined-basic"
                      label="Number of products registered with the PPB to date"
                      variant="outlined"
                      name="NumberOfProductsRegisteredWithThePPBToDate"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.NumberOfProductsRegisteredWithThePPBToDate}
                      error={
                        !!errors.NumberOfProductsRegisteredWithThePPBToDate &&
                        touched.NumberOfProductsRegisteredWithThePPBToDate
                      }
                      helperText={
                        touched.NumberOfProductsRegisteredWithThePPBToDate &&
                        !!errors.NumberOfProductsRegisteredWithThePPBToDate &&
                        errors.NumberOfProductsRegisteredWithThePPBToDate
                      }
                    />
                  </Grid>
                  <Grid item sm={12} lg={6} xl={4}>
                    <TextField
                      type="text"
                      disabled={viewOnly}
                      rows={3}
                      multiline
                      id="outlined-basic"
                      label="Products to be included in the insurance formulary? (Enclose list)"
                      variant="outlined"
                      name="ProductsToBeIncludedInTheHospitalFormulary"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.ProductsToBeIncludedInTheHospitalFormulary}
                      // error={
                      //   !!errors.ProductsToBeIncludedInTheHospitalFormulary && touched.ProductsToBeIncludedInTheHospitalFormulary
                      // }
                      helperText={
                        touched.ProductsToBeIncludedInTheHospitalFormulary &&
                        !!errors.ProductsToBeIncludedInTheHospitalFormulary &&
                        errors.ProductsToBeIncludedInTheHospitalFormulary
                      }
                    />
                  </Grid>

                  <Grid item sm={12} lg={6} xl={4}>
                    <h3 className="mb-10">Clients (Tick where applicable)</h3>
                    <FormControl component="fieldset" variant="standard">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={viewOnly}
                              name="Clients"
                              value={"Hospitals"}
                              checked={
                                clientsVal?.includes("Hospitals") ? true : false
                              }
                              onChange={handleClient}
                            />
                          }
                          label="Hospitals"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={viewOnly}
                              checked={
                                clientsVal?.includes(
                                  "Pharmaceutical Distributors"
                                )
                                  ? true
                                  : false
                              }
                              value={"Pharmaceutical Distributors"}
                              name="Clients"
                              onChange={handleClient}
                            />
                          }
                          label="Pharmaceutical Distributors"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={viewOnly}
                              checked={
                                clientsVal?.includes("Pharmaceutical Retailers")
                                  ? true
                                  : false
                              }
                              value={"Pharmaceutical Retailers"}
                              name="Clients"
                              onChange={handleClient}
                            />
                          }
                          label="Pharmaceutical Retailers"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={viewOnly}
                              checked={
                                clientsVal?.includes("Institutions")
                                  ? true
                                  : false
                              }
                              value={"Institutions"}
                              name="Clients"
                              onChange={handleClient}
                            />
                          }
                          label="Institutions"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={viewOnly}
                              checked={clientOther}
                              value={"other"}
                              name="Clients"
                              onChange={handleClient}
                            />
                          }
                          label="Others Please specify"
                        />

                        {clientOther && (
                          <>
                            <br />
                            <TextField
                              type="text"
                              disabled={viewOnly}
                              id="outlined-basic"
                              label="Other client"
                              variant="outlined"
                              name="otherClient"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.otherClient}
                              error={
                                !!errors.otherClient && touched.otherClient
                              }
                              helperText={
                                touched.otherClient &&
                                !!errors.otherClient &&
                                errors.otherClient
                              }
                            />
                          </>
                        )}
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item sm={12} lg={6} xl={4}>
                    <h3 className="mb-10">
                      Enclose the following documents for {brandName} to be
                      included in the insurance formulary
                    </h3>
                    <FormControl component="fieldset" variant="standard">
                      <FormGroup>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <FormControlLabel
                              control={
                                <>
                                  {uploadedFiles("registrationcertificates") ? (
                                    <div className="image-preview">
                                      <img
                                        onClick={() =>
                                          openInNewTab(
                                            "registrationcertificates"
                                          )
                                        }
                                        src={uploadedFiles(
                                          "registrationcertificates"
                                        )}
                                        alt=""
                                      />
                                      {viewOnly ? (
                                        ""
                                      ) : (
                                        <CancelIcon
                                          className="remove-image"
                                          titleAccess="Remove"
                                          onClick={() =>
                                            deleteDrugRequestFormFiles(
                                              uploadedFiles(
                                                "registrationcertificates"
                                              ),
                                              "registrationcertificates"
                                            )
                                          }
                                        />
                                      )}
                                    </div>
                                  ) : (
                                    <FileBrowse
                                      disabled={viewOnly}
                                      name="registrationcertificates"
                                      getUploadedPath={getUploadedPath}
                                      nameOfFile={"registrationcertificates"}
                                    />
                                  )}
                                </>
                              }
                              label="PPB Product registration certificate"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControlLabel
                              control={
                                <>
                                  {uploadedFiles("retentioncertificates") ? (
                                    <div className="image-preview">
                                      <img
                                        onClick={() =>
                                          openInNewTab("retentioncertificates")
                                        }
                                        src={uploadedFiles(
                                          "retentioncertificates"
                                        )}
                                        alt=""
                                      />
                                      {viewOnly ? (
                                        ""
                                      ) : (
                                        <CancelIcon
                                          className="remove-image"
                                          titleAccess="Remove"
                                          onClick={() =>
                                            deleteDrugRequestFormFiles(
                                              uploadedFiles(
                                                "retentioncertificates"
                                              ),
                                              "retentioncertificates"
                                            )
                                          }
                                        />
                                      )}
                                    </div>
                                  ) : (
                                    <FileBrowse
                                      disabled={viewOnly}
                                      name="retentioncertificates"
                                      getUploadedPath={getUploadedPath}
                                      nameOfFile={"retentioncertificates"}
                                    />
                                  )}
                                </>
                              }
                              label="Valid/Current PPB Retention certificates"
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <FormControlLabel
                              control={
                                <>
                                  {uploadedFiles("productcharacteristics") ? (
                                    <div className="image-preview">
                                      <img
                                        onClick={() =>
                                          openInNewTab("productcharacteristics")
                                        }
                                        src={uploadedFiles(
                                          "productcharacteristics"
                                        )}
                                        alt=""
                                      />
                                      {viewOnly ? (
                                        ""
                                      ) : (
                                        <CancelIcon
                                          className="remove-image"
                                          titleAccess="Remove"
                                          onClick={() =>
                                            deleteDrugRequestFormFiles(
                                              uploadedFiles(
                                                "productcharacteristics"
                                              ),
                                              "productcharacteristics"
                                            )
                                          }
                                        />
                                      )}
                                    </div>
                                  ) : (
                                    <FileBrowse
                                      disabled={viewOnly}
                                      name="productcharacteristics"
                                      getUploadedPath={getUploadedPath}
                                      nameOfFile={"productcharacteristics"}
                                    />
                                  )}
                                </>
                              }
                              label="Summary of product characteristics (SMPC)"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControlLabel
                              control={
                                <>
                                  {uploadedFiles("patientleaflet") ? (
                                    <div className="image-preview">
                                      <img
                                        onClick={() =>
                                          openInNewTab("patientleaflet")
                                        }
                                        src={uploadedFiles("patientleaflet")}
                                        alt=""
                                      />
                                      {viewOnly ? (
                                        ""
                                      ) : (
                                        <CancelIcon
                                          className="remove-image"
                                          titleAccess="Remove"
                                          onClick={() =>
                                            deleteDrugRequestFormFiles(
                                              uploadedFiles("patientleaflet"),
                                              "patientleaflet"
                                            )
                                          }
                                        />
                                      )}
                                    </div>
                                  ) : (
                                    <FileBrowse
                                      disabled={viewOnly}
                                      name="patientleaflet"
                                      getUploadedPath={getUploadedPath}
                                      nameOfFile={"patientleaflet"}
                                    />
                                  )}
                                </>
                              }
                              label="Patient leaflet"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControlLabel
                              control={
                                <>
                                  {uploadedFiles("primaryproductpackaging") ? (
                                    <div className="image-preview">
                                      <img
                                        onClick={() =>
                                          openInNewTab(
                                            "primaryproductpackaging"
                                          )
                                        }
                                        src={uploadedFiles(
                                          "primaryproductpackaging"
                                        )}
                                        alt=""
                                      />
                                      {viewOnly ? (
                                        ""
                                      ) : (
                                        <CancelIcon
                                          className="remove-image"
                                          titleAccess="Remove"
                                          onClick={() =>
                                            deleteDrugRequestFormFiles(
                                              uploadedFiles(
                                                "primaryproductpackaging"
                                              ),
                                              "primaryproductpackaging"
                                            )
                                          }
                                        />
                                      )}
                                    </div>
                                  ) : (
                                    <FileBrowse
                                      disabled={viewOnly}
                                      name="primaryproductpackaging"
                                      getUploadedPath={getUploadedPath}
                                      nameOfFile={"primaryproductpackaging"}
                                    />
                                  )}
                                </>
                              }
                              label="Image of Primary product packaging"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControlLabel
                              control={
                                <>
                                  {uploadedFiles("productpackaging") ? (
                                    <div className="image-preview">
                                      <img
                                        onClick={() =>
                                          openInNewTab("productpackaging")
                                        }
                                        src={uploadedFiles("productpackaging")}
                                        alt=""
                                      />
                                      {viewOnly ? (
                                        ""
                                      ) : (
                                        <CancelIcon
                                          className="remove-image"
                                          titleAccess="Remove"
                                          onClick={() =>
                                            deleteDrugRequestFormFiles(
                                              uploadedFiles("productpackaging"),
                                              "productpackaging"
                                            )
                                          }
                                        />
                                      )}
                                    </div>
                                  ) : (
                                    <FileBrowse
                                      disabled={viewOnly}
                                      name="productpackaging"
                                      getUploadedPath={getUploadedPath}
                                      nameOfFile={"productpackaging"}
                                    />
                                  )}
                                </>
                              }
                              label="Secondary product packaging"
                            />
                          </Grid>
                        </Grid>
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item sm={12} lg={6} xl={4}>
                    <h3 className="mb-10">Nature of Business</h3>
                    <FormControl component="fieldset" variant="standard">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={viewOnly}
                              name="natureOfBusiness"
                              value={"Pharmaceutical importer"}
                              checked={
                                natureOfBusinessVal ===
                                "Pharmaceutical importer"
                              }
                              onChange={handleChangeNature}
                            />
                          }
                          label="Pharmaceutical importer"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={viewOnly}
                              checked={
                                natureOfBusinessVal ==
                                "Pharmaceutical manufacturer"
                              }
                              value={"Pharmaceutical manufacturer"}
                              onChange={handleChangeNature}
                              name="NatureOfBusiness"
                            />
                          }
                          label="Pharmaceutical manufacturer"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={viewOnly}
                              checked={businessNatureOther}
                              value={"other"}
                              name="natureOfBusiness"
                              onChange={handleChangeNature}
                            />
                          }
                          label="If other please specify"
                        />

                        {businessNatureOther && (
                          <>
                            <br />
                            <TextField
                              type="text"
                              disabled={viewOnly}
                              id="outlined-basic"
                              label="Nature of Business"
                              variant="outlined"
                              name="natureOfBusinessOther"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.natureOfBusinessOther}
                              error={
                                !!errors.natureOfBusinessOther &&
                                touched.natureOfBusinessOther
                              }
                              helperText={
                                touched.natureOfBusinessOther &&
                                !!errors.natureOfBusinessOther &&
                                errors.natureOfBusinessOther
                              }
                            />
                          </>
                        )}
                      </FormGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={6} xl={3}>
                    <TextField
                      type="number"
                      disabled={values.TradePricePerUnit ? true : viewOnly}
                      id="outlined-basic"
                      label="Trade price per unit"
                      variant="outlined"
                      name="TradePricePerUnit"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.TradePricePerUnit}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">/- KES</InputAdornment>
                        ),
                      }}
                      error={
                        !!errors.TradePricePerUnit && touched.TradePricePerUnit
                      }
                      helperText={
                        touched.TradePricePerUnit &&
                        !!errors.TradePricePerUnit &&
                        errors.TradePricePerUnit
                      }
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} xl={3}>
                    <TextField
                      type="number"
                      disabled={values.TradePricePerUnit ? true : viewOnly}
                      id="outlined-basic"
                      label="Rebate Percentage"
                      variant="outlined"
                      name="RebatePercentage"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.RebatePercentage}
                     
                      error={
                        !!errors.TradePricePerUnit && touched.TradePricePerUnit
                      }
                      helperText={
                        touched.TradePricePerUnit &&
                        !!errors.TradePricePerUnit &&
                        errors.TradePricePerUnit
                      }
                    />
                  </Grid>
                  
                  <Grid item xs={12} lg={6} xl={3}>
                    <TextField
                      type="text"
                      disabled={brandName ? true : viewOnly}
                      id="outlined-basic"
                      label="API Name"
                      variant="outlined"
                      name="name"
                      value={brandName}
                      error={!!errors.name && touched.name}
                      helperText={touched.name && !!errors.name && errors.name}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6} xl={3}>
                    <TextField
                      type="text"
                      disabled={values.brand_name ? true : viewOnly}
                      id="outlined-basic"
                      label="Brand Name"
                      variant="outlined"
                      name="brand_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.brand_name}
                      error={!!errors.brand_name && touched.brand_name}
                      helperText={
                        touched.brand_name &&
                        !!errors.brand_name &&
                        errors.brand_name
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <h3 className="mb-10">Insurance Company</h3>
                    <Grid container columnSpacing={2} rowSpacing={4}>
                      {insuranceCompany?.length > 0
                        ? insuranceCompany?.map((item: any) => (
                            <Grid item xs={12} sm={6} lg={4} xl={3}>
                              <div className="justify-start">
                                <Checkbox
                                  disabled={viewOnly}
                                  onClick={insuranceCompanyChange}
                                  checked={
                                    insuranceCompanyIds?.length > 0 &&
                                    insuranceCompanyIds?.includes(
                                      item?.InsuranceCompanyId
                                    )
                                      ? true
                                      : false
                                  }
                                  value={item?.InsuranceCompanyId}
                                />
                                <img
                                  src={Env.REACT_APP_ASSET_PATH + item.LogoURL}
                                  width="60px"
                                  style={{ maxHeight: "50px" }}
                                  className="mr-10"
                                  alt=""
                                />
                                {/* <ListItemText primary={item.Name} /> */}
                                <Typography
                                  variant="h6"
                                  style={{ fontWeight: "400" }}
                                >
                                  {item.Name}
                                  {insuranceCompanyIds?.includes(
                                    item?.InsuranceCompanyId
                                  )
                                    ? true
                                    : false}
                                </Typography>
                              </div>
                            </Grid>
                          ))
                        : ""}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {viewOnly && (
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <button
                            type="submit"
                            onClick={() =>
                              navigate(`${REQUEST_FORMULARY_APPLICATIONS}`)
                            }
                            className="btn btn-primary w-100"
                          >
                            Back
                          </button>
                        </Grid>
                      )}
                      {!viewOnly && (
                        <>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <button
                              type="submit"
                              onClick={() =>
                                navigate(`${REQUEST_FORMULARY_APPLICATIONS}`)
                              }
                              className="btn btn-primary w-100"
                            >
                              Cancel
                            </button>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <button
                              type="submit"
                              onClick={() => {
                                setSubmitTypeVal(2);
                              }}
                              className="btn btn-primary w-100"
                            >
                              {isSubmitting && submitType === 2 && !isNotValid
                                ? "Loading..."
                                : "Save Draft "}
                            </button>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <button
                              type="submit"
                              onClick={() => {
                                setSubmitTypeVal(3);
                              }}
                              className="btn btn-primary w-100"
                            >
                              {isSubmitting && submitType === 1 && !isNotValid
                                ? "Loading..."
                                : "Save & Submit"}
                            </button>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default ApplicationsDetails;
