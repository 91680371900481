import axios from "axios";
import { EndPoint, Env } from "../../common/constants";
import showAlert from "../../common/utils/alert";

export async function signupAction(payload: any) {
  try {
    const response = await axios.post(
      `${Env.BACKEND_URL}${EndPoint.SIGNUP}` as string,
      payload
    );
    return response;
  } catch (err: any) {
    showAlert(2, err?.response?.data?.Message);
    return err;
  }
}

export async function getManufacturerAction() {
  try {
    const response = await axios.get(
      `${Env.AUTH_BASE_URL}${Env.REACT_APP_VERSON}${EndPoint.MANUFACTURER}` as string,
      {
        headers: {
          "x-api-key": `${Env.REACT_APP_X_KEY}`,
        }
      }
    );
    return response;
  } catch (err: any) {
    showAlert(2, err?.response?.data?.Message);
    return err;
  }
}

export async function getCitiesAction(COUNTRY_ID: number) {
  try {
    const response = await axios.get(
      `${Env.AUTH_BASE_URL}${Env.REACT_APP_VERSON}${EndPoint.CITIES}?Countryid=` + COUNTRY_ID as string,
      {
        headers: {
          "x-api-key": `${Env.REACT_APP_X_KEY}`,
        }
      }
    );
    return response;
  } catch (err: any) {
    showAlert(2, err?.response?.data?.Message);
    return err;
  }
}
