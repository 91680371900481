const login = {
  email: "",
  password: "",
};

const signup = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  phone: "",
  manufactureCompany: "",
  title: "",
  phonecode: "254",
  country: "ke",
  city: "",
  phone_number: ""

};

const forgotPassword = {
  email: "",
};

const resetPassword = {
  newPassword: "",
  confirmNewPassword: "",
};

const updatePassword = {
  oldPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

const manageRebateFilter = {
  fromDate: "",
  toDate: "",
};

const medicineFilter = {
  fromDate: "",
  toDate: "",
  insuranceCompanyId: "",
};

const medicineOrderFilter = {
  fromDate: "",
  toDate: "",
  status: "",
  pharmacyId: "",
};

const requestContentForm = {
  apiName: "",
  brandName: '',
  tradePrice: "",
  rebatePercentage: 0,
  nonBranded: ""
};

const applicationFormulary = {
  NameOfCompany: "",
  BusinessRegistrationNumber: "",
  PPBPremiseRegistrationNumber: "",
  YearEstablished: "",
  CountryId: "",
  Town: "",
  County: "",
  PostalAddress: "",
  OfficialEmail: "",
  TelephoneNumber: "",
  AlternativeContact: "",
  CompanyWebsite: "",
  NumberOfProductsRegisteredWithThePPBToDate: 0,
  ProductsToBeIncludedInTheHospitalFormulary: [],
  NatureOfBusiness: "",
  natureOfBusinessOther: "",
  certificateofregistration: "",
  taxcompliancecertificate: "",
  businesspermit: "",
  certificateofregistrationpremises: "",
  certificatefromthePPB: "",
  Clients: [],
  otherClient: "",
  TradePricePerUnit: 0,
  RebatePercentage: 0,
  InsuranceCompanies: <any>[],
  name: "",
  brand_name: "",
};




const FORM_FIELD = {
  login,
  signup,
  forgotPassword,
  resetPassword,
  updatePassword,
  manageRebateFilter,
  medicineFilter,
  requestContentForm,
  applicationFormulary,
  medicineOrderFilter
};

export default FORM_FIELD;
