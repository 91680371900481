import {Grid, FormControl, TextField } from "@mui/material";
import { Button, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import SCHEMA from "../../validations/schema";
import FORM_FIELD from "../../validations/form-field";

function FilterComponent({ applyFilter }: any) {
  const handleClear = () => {
    applyFilter(FORM_FIELD.manageRebateFilter);
  };

  const handleFilter = (values: any) => {
    if (values.fromDate !== "" || values.toDate !== "") applyFilter(values);
  };

  return (
    <div>
      <Formik
        initialValues={FORM_FIELD.manageRebateFilter}
        validationSchema={SCHEMA.manageRebateFilter}
        onSubmit={handleFilter}
        enableReinitialize
      >
        {({ handleChange, handleBlur, values, errors }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3.5} lg={2.6}>
                <Typography>From Date</Typography>
                <FormControl fullWidth>
                  <TextField
                    name="fromDate"
                    type="date"
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fromDate}
                    error={!!errors.fromDate}
                    helperText={!!errors.fromDate && errors.fromDate}
                    inputProps={{ max: values.toDate }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3.5} lg={2.6}>
                <Typography>To Date</Typography>
                <FormControl fullWidth>
                  <TextField
                    name="toDate"
                    type="date"
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.toDate}
                    error={!!errors.toDate}
                    helperText={!!errors.toDate && errors.toDate}
                    inputProps={{ min: values.fromDate }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={5} lg={4}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="medium"
                  sx={{
                    fontWeight: "bold",
                    minWidth: "auto",
                    marginTop: { xs: "0px", md: "26px" },
                    marginRight: "16px",
                  }}
                >
                  Apply
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  type="reset"
                  size="medium"
                  sx={{
                    fontWeight: "bold",
                    minWidth: "auto",
                    marginTop: { xs: "0px", md: "26px" },
                  }}
                  onClick={handleClear}
                  disabled={values.toDate === "" && values.fromDate === ""}
                >
                  Clear
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default FilterComponent;
