import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
// import { ReducersModal } from "../../modal";

function GlobalLoader() {
  const  { isLoading }  = useSelector(
    (state: any) => state.globalLoader
  );
  
  return (
    <Backdrop
      sx={{
        zIndex: "99999999999",
        color: (theme) => theme.palette.common.white,
      }}
      // open={true}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default GlobalLoader;
