import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { InsuranceCompany } from "./models";
import { currencyFormat, dataFormatForInsuranceMedicineDialog } from "../../common/utils";

function InsuranceMedicineDataDialog({
  open,
  onClose,
  insuranceMedicineData,
  insuranceIdsData,
  insuranceNamesData,
}: any) {
  let insuranceMedicineModifiedData = [];
  if(open){
    insuranceMedicineModifiedData = dataFormatForInsuranceMedicineDialog(insuranceIdsData, insuranceNamesData, insuranceMedicineData);
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title" sx={{ position: "relative" }}>
          Medicine Data
          <div onClick={onClose}>
            <CloseOutlinedIcon
              titleAccess="Click to close"
              sx={{
                position: "absolute",
                right: 20,
                top: 15,
                cursor: "pointer",
              }}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table sx={{ width: "100%" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Insurance Company</TableCell>
                  <TableCell>No. of Prescription</TableCell>
                  <TableCell>Quantity Sold</TableCell>
                  <TableCell>Total Rebate Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {insuranceMedicineModifiedData.length > 0 ? (
                  insuranceMedicineModifiedData.map(
                    (
                      {
                        TotalSold,
                        InsuranceCompanyName,
                        RebateAmount,
                        TotalPrescribed,
                      }: InsuranceCompany,
                      index: any
                    ) => {
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {InsuranceCompanyName
                              ? InsuranceCompanyName
                              : "N/A"}
                          </TableCell>
                          <TableCell>{TotalPrescribed ? TotalPrescribed : "N/A"}</TableCell>
                          <TableCell>{TotalSold ? TotalSold : "N/A"}</TableCell>
                          <TableCell>
                            {RebateAmount
                              ? currencyFormat(RebateAmount)
                              : "N/A"}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} className="no-data-found-cell">
                      No Data Found!
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default InsuranceMedicineDataDialog;
