import { EndPoint } from "../../common/constants";
import axiosI from "../../setup/axios/axios.instance";

const fetchCsvData = async (filterValue: any) => {
  try {
    const filterParams = `?FromDate=${filterValue.FromDate}&ToDate=${filterValue.ToDate}&Search=${filterValue.Search}`;
    const response = await axiosI.get(
      `${EndPoint.MANAGE_REBATE_LIST_EXPORT}${filterParams}`
    );
    return response?.data;
  } catch (err) {
    return err;
  }
};

export default fetchCsvData;
