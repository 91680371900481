import axios from "axios";
import { EndPoint, Env } from "../../common/constants";
import showAlert from "../../common/utils/alert";

export async function forgotPasswordAction(payload: any) {
  try {
    const response = await axios.patch(
      `${Env.AUTH_BASE_URL}${Env.REACT_APP_VERSON}${EndPoint.FORGOT_PASSWORD}` as string,
      payload
    );
    return response;
  } catch (err: any) {
    showAlert(2, err?.response?.data?.Message);
    return err;
  }
}
