import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
} from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import { currencyFormat, getPayType } from "../../common/utils";
import { RootState } from "../../setup/store/store";
import { Amount } from "./models";

function TableComponent({
  filterValue,
  handleChangePage,
  handleChangeRowsPerPage,
}: any) {
  const { Amounts, TotalRecords } = useSelector(
    (state: RootState) => state.manageRebate?.entities
  );
  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="medium">
          <TableHead>
            <TableRow>
              <TableCell>Date of Payment</TableCell>
              <TableCell>Total Amount Paid</TableCell>
              <TableCell>Period</TableCell>
              <TableCell>Payment Initiated Date</TableCell>
              <TableCell>Payment Completed Date</TableCell>
              <TableCell>Pay Type</TableCell>
              <TableCell>Reference ID #</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Amounts.length > 0 ? (
              Amounts.map(
                (
                  {
                    PaymentDate,
                    Amount,
                    FromDate,
                    ToDate,
                    Reference,
                    PaymentInitiatedDate,
                    PaymentCompletedDate,
                    PayType,
                  }: Amount,
                  index
                ) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      {PaymentDate
                        ? moment(PaymentDate).format("DD/MM/YYYY")
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {Amount ? currencyFormat(Amount) : "N/A"}
                    </TableCell>
                    <TableCell>
                      {FromDate && ToDate
                        ? moment(FromDate).format("DD/MM/YYYY") +
                          " - " +
                          moment(ToDate).format("DD/MM/YYYY")
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {PaymentInitiatedDate
                        ? moment(PaymentInitiatedDate).format("DD/MM/YYYY")
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      {PaymentCompletedDate
                        ? moment(PaymentCompletedDate).format("DD/MM/YYYY")
                        : "N/A"}
                    </TableCell>
                    <TableCell>{getPayType(PayType)}</TableCell>
                    <TableCell>{Reference ? Reference : "N/A"}</TableCell>
                  </TableRow>
                )
              )
            ) : (
              <TableRow
                key={1}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {" "}
                <TableCell colSpan={7} className="no-data-found-cell">
                  {"No Record Found"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={TotalRecords}
            rowsPerPage={filterValue.Limit}
            page={filterValue.Page - 1}
            onPageChange={(e, value): any => handleChangePage(value)}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={<span>Rows:</span>}
            sx={{
              ".MuiTablePagination-selectLabel, .MuiTablePagination-input": {
                fontWeight: "bold",
                color: "primary",
              },
            }}
          />
        </TableRow>
      </TableFooter>
    </>
  );
}

export default TableComponent;
