import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import logoImg from "../../assets/images/logo.svg";
import loginBannerImg from "../../assets/images/auth-banner.png";
import "./login.css";
import { Form, Formik } from "formik";
import FORM_FIELD from "../../validations/form-field";
import SCHEMA from "../../validations/schema";
import { RoutesName } from "../../common/constants";
import {
  decodeJwtToken,
  getAccessToken,
  setAccessToken,
  setRefreshToken,
  setUserEmail,
  setUserName,
  setUserID,
} from "../../common/utils";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import showAlert from "../../common/utils/alert";
import { useDispatch } from "react-redux";
import {
  globalLoaderFalse,
  globalLoaderTrue,
} from "../../redux/slice/global.loader.slice";
import { loginAction } from "./action";
import { Role } from "../../types/global.types";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (getAccessToken()) {
      navigate(RoutesName.MANAGE_REBATE_LIST);
    }
  }, []);

  const handleSubmit = (values: any, action: any) => {
    const payload = {
      Password: values.password,
      PlatformEnumId: 3,
      PrincipalIdentifierValue: values.email,
      TokenExpiryTime: "2d",
    };

    dispatch(globalLoaderTrue());
    loginAction(payload)
      .then((res: any) => {
        const { AccessToken, RefreshToken } =
          res?.data?.Data?.PrincipalIdentifier?.Device;
        const { PrincipalIdentifierValue } =
          res?.data?.Data?.PrincipalIdentifier;
        const { FirstName, LastName } = res?.data?.Data;
        const decode = decodeJwtToken(AccessToken);
        if (decode?.userRoleId === Role.brandManufacturer) {
        } else {
          showAlert(2, "User not valid");
        }

        setAccessToken(AccessToken);
        setRefreshToken(RefreshToken);
        setUserEmail(PrincipalIdentifierValue);
        setUserName(`${FirstName} ${LastName}`);
        setUserID(decode.userId);

        showAlert(1, "You're logged in successfully.");
        action.setSubmitting(false);
        dispatch(globalLoaderFalse());
        navigate(RoutesName.MANAGE_REBATE_LIST);
      })
      .catch((err) => {
        dispatch(globalLoaderFalse());
        action.setSubmitting(false);
      });
  };

  return (
    <div className="page-login">
      <div className="login-content">
        <div className="left-portion">
          <div className="banner-img-cover">
            <img alt="" src={loginBannerImg} />
          </div>
          <img alt="" className="logo-image" src={logoImg} />
        </div>
        <div className="right-portion">
          <img alt="" className="logo-image for-mobile" src={logoImg} />
          <h2 className="color-blue bold mb-20">Welcome to Livia</h2>
          <Formik
            initialValues={FORM_FIELD.login}
            validationSchema={SCHEMA.login}
            onSubmit={handleSubmit}
          >
            {({
              isSubmitting,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
            }) => {
              return (
                <Form className="w-100">
                  <Grid container spacing={6}>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-basic"
                        label="Email Address"
                        variant="outlined"
                        name="email"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        error={!!errors.email && touched.email}
                        helperText={
                          touched.email && !!errors.email && errors.email
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        type="password"
                        id="outlined-basic"
                        label="Password"
                        variant="outlined"
                        name="password"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        error={!!errors.password && touched.password}
                        helperText={
                          touched.password &&
                          !!errors.password &&
                          errors.password
                        }
                      />
                    </Grid>
                  </Grid>
                  <div style={{ display: "flex" }}>
                    <div className="left mt-10">
                      <Link to={RoutesName.SIGNUP}>Sign Up?</Link>
                    </div>
                    <div
                      className="right mt-10"
                      style={{ width:"83%",paddingLeft:"5px" }}
                    >
                      <Link to={RoutesName.FORGOT_PASSWORD}>
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                  <div className="btn-cover mt-30">
                    <button type="submit" className="btn btn-primary">
                      {isSubmitting ? "Loading..." : "Get Started"}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Login;
