import React from 'react';
import { Link } from 'react-router-dom';
import errorImg from '../../assets/images/404-error.svg';

function PageNotFound() {
    return (
        <div className="page-not-found" style={{textAlign:'center', display:'flex', alignItems:'center', justifyContent: 'center', flexDirection:'column', minHeight:'100vh', padding: '50px 20px'}}>
            <img alt="page-not-found" src={errorImg} style={{width: '100px', marginBottom: ' 50px'}}/>
            <h2 className="text-blue bold">404 page not found. <Link to="/">Go to Home</Link></h2>
          </div>
    );
}

export default PageNotFound;
