export type JwtDecodeT = {
  exp: number;
  iat: number;
  userId: string;
  userRoleId: string;
};

export enum Role {
  brandManufacturer = "26",
}
