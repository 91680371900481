import axios, { AxiosError, AxiosRequestConfig } from "axios";
import {
  EndPoint,
  Env,
  ResponseCode,
  RoutesName,
} from "../../common/constants";
import {
  getAccessToken,
  setAccessToken,
  setRefreshToken,
} from "../../common/utils";
import showAlert from "../../common/utils/alert";

const axiosI = axios.create({
  baseURL: `${Env.BASE_URL}${Env.REACT_APP_VERSON}`,
  timeout: 5000,
});

const requestHandler = (request: AxiosRequestConfig) => {
  if (request.headers){
    request.headers.Authorization = `Bearer ${getAccessToken()}`;
    request.headers['ngrok-skip-browser-warning'] = `69420`;
  }
  return request;
};

axiosI.interceptors.request.use((request) => requestHandler(request));

const refreshTokenHandler = async () => {
  const token = localStorage.getItem("accessToken") || "";
  const refreshToken = localStorage.getItem("refreshToken") || "";
  const payload = {
    AccessToken: token,
    RefreshToken: refreshToken,
    TokenExpiryTime: "2d",
  };

  return axios.post(
    `${Env.AUTH_BASE_URL}${Env.REACT_APP_VERSON}${EndPoint.REFRESH_TOKEN}` as string,
    payload
  );
};

const responseErrorHandler = async (error: AxiosError) => {
  const originalRequest = error.config;
  if (error.response?.status === ResponseCode.UNAUTHORIZED) {
    try {
      const refreshToken = await refreshTokenHandler();

      const { status, data } = refreshToken;
      if (status === ResponseCode.CREATED) {
        const { AccessToken, RefreshToken } = data?.Data;

        setAccessToken(AccessToken);
        setRefreshToken(RefreshToken);

        if (originalRequest.headers)
          originalRequest.headers["Authorization"] = `Bearer ${AccessToken}`;
        return axios(originalRequest);
      }
    } catch (_error) {
      localStorage.clear();
      window.location.href = RoutesName.LOGIN;
      showAlert(2);
    }
  } else if (error.response?.status === ResponseCode.FORBIDDEN) {
    localStorage.clear();
    window.location.href = RoutesName.LOGIN;
  } else {
    //@ts-ignore
    showAlert(2, error.response?.data?.Message);
    return Promise.reject(error);
  }
};

axiosI.interceptors.response.use(
  (response) => response,
  (error) => responseErrorHandler(error)
);

export default axiosI;
