import React, { useState } from 'react';
import AWS from 'aws-sdk'
import { Env } from '../../common/constants'

const S3_BUCKET = Env.REACT_APP_S3_BUCKET;
const REGION = Env.REACT_APP_S3_REGION;


AWS.config.update({
    accessKeyId: Env.REACT_APP_S3_ACCESS_KEY,
    secretAccessKey: Env.REACT_APP_S3_SECRET_KEY,
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})

export const uploadToS3 = async (file, fileName, type) => {
    return new Promise((resolve, reject) => {
        const params = {
            Key: fileName,
            Body: file,
            ACL: 'public-read',
            ContentType: type
        };

        // myBucket.getSignedUrl('putObject',params, (err, data) => {
        //     if (err) {
        //         reject(err);
        //     }
        //     console.log(data,'s3  response');
        //     resolve(data.Location);
        // });
        myBucket.upload(params, (err, data) => {
            if (err) {
                console.log(err, 's3  error');
                reject(err);
            }
            console.log(data.Location, 's3  response');
            resolve(data.Location);
        });
    })
}


export const deleteFromS3 = async (fileName) => {
    return new Promise((resolve, reject) => {
        const params = {
            Key: fileName,
        };
        myBucket.deleteObject(params, (err, data) => {
            if (err) {
                reject(err);
            }
            resolve(data);
        });
    })
}


